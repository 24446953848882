/** @jsxImportSource @emotion/react */

import {
  shareButtonStyle,
  textStyle,
  logoStyle,
  smallShareButtonStyle,
  smallTextStyle,
  smallLogoStyle,
  smallLinkStyle,
  linkStyle,
} from '../../style/shareButtonStyles';
import { staticImageUrl } from '../../util/images';
import track from '../../lib/events';
import { SOCIAL_SHARE_DEFAULT_CONFIGS } from '../../config/constants';

const whatsapp = staticImageUrl('whatsapp.png');

const WhatsAppShare = (props: {
  text: string;
  url: string;
  name: string;
  size?: 'small';
  button?: React.ReactNode;
  className?: string;
  campaignName?: string;
  utmMedium?: string;
}) => {
  const {
    text = SOCIAL_SHARE_DEFAULT_CONFIGS.text,
    url = SOCIAL_SHARE_DEFAULT_CONFIGS.url,
    name = '',
    size,
    button,
    className,
    campaignName = 'share',
    utmMedium = name,
  } = props;

  const urlObj = new URL(url);
  urlObj.searchParams.set('utm_source', 'whatsapp');
  urlObj.searchParams.set('utm_campaign', campaignName);
  urlObj.searchParams.set('utm_medium', utmMedium);

  const urlWithUtm = urlObj.toString();

  const encodedProps = {
    text: encodeURIComponent(text),
    url: encodeURIComponent(urlWithUtm),
  };

  return (
    <a
      href={`whatsapp://send?text=${encodedProps.text}%0A${encodedProps.url}`}
      data-action="share/whatsapp/share"
      css={size === 'small' ? smallLinkStyle : linkStyle}
      onClick={() => {
        track('Server: User Clicked Share Button', {
          name,
          source: 'whats app',
          campaignName,
          utmMedium,
        });
      }}
      className={className}
    >
      {button || (
        <button
          css={size === 'small' ? smallShareButtonStyle : shareButtonStyle}
        >
          <img
            src={whatsapp}
            css={size === 'small' ? smallLogoStyle : logoStyle}
            alt=""
          />
          <p css={size === 'small' ? smallTextStyle : textStyle}>WhatsApp</p>
        </button>
      )}
    </a>
  );
};

export default WhatsAppShare;
