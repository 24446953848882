import { css, Global } from '@emotion/react';
import { staticImageUrl } from '../util/images';
import { oldFontSizes } from './theme';
import { mq } from '../util/scale';
import zIndex from '../lib/zIndex';
import * as fontStyles from './fontStyles';

const styles = css`
  @import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Signika:wght@300..700&display=swap');

  @font-face {
    font-family: 'Blanco';
    src: url('${staticImageUrl('/fonts/Blanco-Regular.eot')}');
    src: url('${staticImageUrl('/fonts/Blanco-Regular.eot?#iefix')}')
        format('embedded-opentype'),
      url('${staticImageUrl('/fonts/Blanco-Regular.woff2')}') format('woff2'),
      url('${staticImageUrl('/fonts/Blanco-Regular.woff')}') format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
  }

  @font-face {
    font-family: 'Blanco';
    src: url('${staticImageUrl('/fonts/Blanco-Italic.eot')}');
    src: url('${staticImageUrl('/fonts/Blanco-Italic.eot?#iefix')}')
        format('embedded-opentype'),
      url('${staticImageUrl('/fonts/Blanco-Italic.woff2')}') format('woff2'),
      url('${staticImageUrl('/fonts/Blanco-Italic.woff')}') format('woff');
    font-weight: 400;
    font-style: italic;
    font-stretch: normal;
  }

  @font-face {
    font-family: 'Blanco';
    src: url('${staticImageUrl('/fonts/Blanco-Medium.eot')}');
    src: url('${staticImageUrl('/fonts/Blanco-Medium.eot?#iefix')}')
        format('embedded-opentype'),
      url('${staticImageUrl('/fonts/Blanco-Medium.woff2')}') format('woff2'),
      url('${staticImageUrl('/fonts/Blanco-Medium.woff')}') format('woff');
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
  }

  @font-face {
    font-family: 'Blanco';
    src: url('${staticImageUrl('/fonts/Blanco-MediumItalic.eot')}');
    src: url('${staticImageUrl('/fonts/Blanco-MediumItalic.eot?#iefix')}')
        format('embedded-opentype'),
      url('${staticImageUrl('/fonts/Blanco-MediumItalic.woff2')}')
        format('woff2'),
      url('${staticImageUrl('/fonts/Blanco-MediumItalic.woff')}') format('woff');
    font-weight: 600;
    font-style: italic;
    font-stretch: normal;
  }

  @font-face {
    font-family: 'Blanco';
    src: url('${staticImageUrl('/fonts/Blanco-Bold.eot')}');
    src: url('${staticImageUrl('/fonts/Blanco-Bold.eot?#iefix')}')
        format('embedded-opentype'),
      url('${staticImageUrl('/fonts/Blanco-Bold.woff2')}') format('woff2'),
      url('${staticImageUrl('/fonts/Blanco-Bold.woff')}') format('woff');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
  }

  @font-face {
    font-family: 'Blanco';
    src: url('${staticImageUrl('/fonts/Blanco-BoldItalic.eot')}');
    src: url('${staticImageUrl('/fonts/Blanco-BoldItalic.eot?#iefix')}')
        format('embedded-opentype'),
      url('${staticImageUrl('/fonts/Blanco-BoldItalic.woff2')}') format('woff2'),
      url('${staticImageUrl('/fonts/Blanco-BoldItalic.woff')}') format('woff');
    font-weight: 700;
    font-style: italic;
    font-stretch: normal;
  }

  body,
  html {
    font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
    font-feature-settings: 'liga' 1, 'calt' 1, 'ss03' 1; /* fix for Chrome */
    color: var(--text-primary);
    background-color: white;
    margin: 0;
    padding: 0;
    overflow: auto;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__input-container {
    width: 100%;
  }

  .custom-date-picker {
    width: 100%;
  }

  .tooltip {
    text-decoration: none !important;
    cursor: auto !important;
  }

  * {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-weight: default;
    letter-spacing: normal;
  }

  h1 {
    ${fontStyles.headingLarge}
  }

  h2 {
    ${fontStyles.headingMedium}
  }

  h3 {
    ${fontStyles.headingSmall}
  }

  p {
    ${fontStyles.paragraph}
  }

  .animate {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }

  .pageHeader {
    font-size: 35px;
    line-height: 45px;
    font-weight: 700;
    margin: auto;
    padding-bottom: 10px;
  }

  h3,
  h4,
  h5 {
    font-weight: 500;
    margin: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 :focus-visible {
    outline: 0;
  }

  .bold {
    font-weight: 700;
  }

  sub {
    font-size: 70%;
    font-weight: 600;
    line-height: 0;
  }

  .box {
    background-color: white;
    border: 1px solid var(--gray-9);
    padding: 10px;
  }

  .flex-it {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  strong {
    font-weight: 700;
  }

  .caption {
    text-align: left;
    font-size: ${oldFontSizes.caption};
  }

  .niceHr {
    border: 1px solid var(--gray-9);
  }

  .smallBr {
    margin: 10px 0;
  }

  .mediumBr {
    margin: 25 px 0;
  }

  .bold {
    font-weight: 700;
  }

  a {
    text-decoration: underline;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    width: fit-content;
    color: var(--text-primary);
    font-weight: 600;
  }

  a:hover {
    color: black;
  }

  .stripeAutofillOverride {
    background-color: transparent !important;
    opacity: 1 !important;
  }

  .contentContainer {
    ${mq({
      width: ['90%', '85%', '70%'],
    })};
    max-width: 1020px;
    margin: auto;
    ${mq({
      marginBottom: ['55px', 'inherit', 'inherit'],
    })};
  }

  .custom-date-picker {
    font-size: ${oldFontSizes.bodyLarge};
    border: 1px solid var(--gray-7);
    padding: 5px 10px;
    border-radius: 4px;
    height: 30px;
    color: var(--text-primary);
    :placeholder {
      color: rgba(27, 60, 52, 0.5);
    }
  }

  .custom-date-picker-calendar {
    position: absolute;
    font-size: ${oldFontSizes.body};
    border: 1px solid transparent;
    box-shadow: 0 1px 3px 0 #cfd7df;
    color: rgba(27, 60, 52, 0.9);
    :placeholder {
      color: rgba(27, 60, 52, 0.5);
    }
    li {
      &--selected {
        background-color: #2684ff;
        color: white;
        font-weight: bold;
        &:hover {
          background-color: #2684ff;
        }
      }
    }
  }

  .custom-date-picker-day {
    fontsize: ${oldFontSizes.bodyLarge};
    color: rgba(27, 60, 52, 0.9);
  }

  .StripeElement--focus {
    // box-shadow: 0 1px 3px 0 #cfd7df;
  }

  .StripeElement--invalid {
    border-color: #fa755a;
  }

  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }

  .content {
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    z-index: ${zIndex('modalContent')};
    border: none;
    width: 60%;
    height: 75%;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #f4f3ef;
  }

  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 350ms ease-in-out;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
  }

  .marker {
    background-image: url('${staticImageUrl('logomark.png')}');
    background-size: cover;
    width: 33px;
    height: 24px;
    cursor: pointer;
  }

  .mapboxgl-popup {
    max-width: 200px;
  }

  .mapboxgl-popup-content {
    text-align: center;
    font-family: 'Open Sans', sans-serif;
  }

  .highlighted {
    z-index: 4499 !important;
  }

  :root {
    // WARNING: ./theme.ts mirrors these vars. Make any changes there as well.
    // by purpose
    --background-gray: #fafafa;
    --background-beige: #f4f3ef;
    --background-dark-green: #072f10;
    --background-light-blue: #ecf4fe;
    --text-primary: #333333;
    --text-secondary: #666666;
    --link-blue: #3789c7; // also used #168fbf, or #2E9BD8
    --link-blue-hover: #327bb3;
    --border-color: #cccccc; // might need border-light & border-dark?
    --box-shadow-color: rgba(0, 0, 0, 0.15);

    --text-light: white; // should be used for text on images for max contrast
    --text-dark: black; // should not be changed in dark mode

    // commonly used accent colors
    --success-green: #27ae60;
    --success-green-hover: #2dc985;
    --error-red: #db3f40;
    --wren-orange: #f79f22;
    --wren-orange-hover: #e89013; // #FFAC35 optional
    --wren-green: #00836c;

    // "one-off" accent colors
    --blue: #1269d3;
    --gold: #ffc500;
    --purple: #ce7fd8;

    // grays
    --gray-0: #030303; // black
    --gray-1: #1a1a1a;
    --gray-2: #333333; // common for text, use this for fills
    --gray-3: #4d4d4d;
    --gray-4: #666666;
    --gray-5: #7f7f7f;
    --gray-6: #999999;
    --gray-7: #b3b3b3; // similar to secondary text
    --gray-8: #cccccc; // common for borders
    --gray-9: #ebebeb;

    // low opacity colors:
    --success-green-low-opacity: #e8fcf0; // for actually transparent version: #27ae6015
    --wren-orange-low-opacity: #fdf3e5; // #f79f2220

    // project categories
    --conservation-blue: #2790f0;
    --policy-purple: #5b27f0;

    // add fonts?
  }

  [data-theme='dark'] {
    // populate with dark theme:
    --background: black;
    --text-primary: white;
    --text-secondary: grey;
    --accent: darkred;
  }
`;

export default styles;

export const globalStyles = <Global styles={styles} />;
