/** @jsxImportSource @emotion/react */
import * as R from 'ramda';
import PropTypes from 'prop-types';

import {
  shareButtonStyle,
  textStyle,
  smallLinkStyle,
  linkStyle,
  smallShareButtonStyle,
  smallTextStyle,
  onlyOnMobile,
  smallLogoStyle,
  logoStyle,
} from '../../style/shareButtonStyles';

import PaperPlane from '../svgs/PaperPlane';
import track from '../../lib/events';
import { SOCIAL_SHARE_DEFAULT_CONFIGS } from '../../config/constants';

// Only displays on mobile
const SMSShare = (props) => {
  const {
    text,
    url,
    name,
    size,
    button,
    className,
    campaignName = 'share',
    utmMedium = name,
  } = props;

  let urlWithUtm;
  if (R.includes('?', url)) {
    urlWithUtm = R.concat(
      url,
      `&utm_source=sms&utm_campaign=${encodeURIComponent(
        campaignName || ''
      )}&utm_medium=${encodeURIComponent(utmMedium || '')}`
    );
  } else {
    urlWithUtm = R.concat(
      url,
      `?utm_source=sms&utm_campaign=${encodeURIComponent(
        campaignName || ''
      )}&utm_medium=${encodeURIComponent(utmMedium || '')}`
    );
  }

  const encodedProps = R.map(encodeURIComponent, {
    text,
    url: urlWithUtm,
  });

  return (
    <a
      href={`sms:&body=${encodedProps.text}%0A${encodedProps.url}`}
      css={[onlyOnMobile, size === 'small' ? smallLinkStyle : linkStyle]}
      onClick={() => {
        track('Server: User Clicked Share Button', {
          name,
          source: 'sms',
          campaignName,
          utmMedium,
        });
      }}
      className={className}
    >
      {button || (
        <button
          css={
            size === 'small'
              ? [smallTextStyle, smallShareButtonStyle]
              : [textStyle, shareButtonStyle]
          }
        >
          <div css={size === 'small' ? smallLogoStyle : logoStyle}>
            <PaperPlane size="100%" color="rgba(0,0,0,0.7)" />
          </div>
          <p css={size === 'small' ? smallTextStyle : textStyle}>{'  Text'}</p>
        </button>
      )}
    </a>
  );
};

SMSShare.defaultProps = {
  ...SOCIAL_SHARE_DEFAULT_CONFIGS,
};

SMSShare.propTypes = {
  text: PropTypes.string,
  url: PropTypes.string,
  name: PropTypes.string,
};

export default SMSShare;
