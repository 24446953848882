/** @jsxImportSource @emotion/react */

// https://www.automasean.blog/react-hash-link/
import HashLinkObserver from 'react-hash-link';
import {
  lazy,
  Suspense,
  useEffect,
  createContext,
  PropsWithChildren,
} from 'react';
import 'url-search-params-polyfill';
import { Global } from '@emotion/react';
import {
  Redirect,
  Route,
  Switch,
  useRouteMatch,
  useLocation,
  useHistory,
} from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import ReferralLanding from './routes/ReferralLanding';
import UpdatedCharter from './routes/UpdatedCharter';

// subscription onboarding
import ContinueOnboarding from './components/onboarding/ContinueOnboarding';

// account
import UpdatePayment from './components/payment/UpdatePayment';
import ForgotPassword from './components/account/ForgotPassword';
import RequestPassReset from './components/account/RequestPassReset';
import ConfirmEmail from './components/account/ConfirmEmail';
import LogOut from './routes/LogOut';
import SubscriptionChurnNotice from './components/payment/SubscriptionChurnNotice';

// misc
import NotFound from './routes/NotFound';
import Private from './routes/Private';
import CancellationMessage from './routes/CancellationMessage';
import BonusIncentive from './components/experiments/BonusIncentive ';

// ui
import Nav from './components/nav/Nav';
import SuspenseFallback from './components/SuspenseFallback';
import * as GroupCallout from './components/onboarding/GroupCallout';

import * as NavConfig from './config/nav';
import Toaster from './components/Toaster';

import GlobalStyles from './style/global';
import usePathHistory from './hooks/usePathHistory';
import { ClaimSpecialOffer, UpdateCurrency } from './actions/user';
import { Step, useOnboardingFlow } from './hooks/useOnboardingFlow';
import useQueryParams from './hooks/useQueryParams';
import SpecialOfferLanding from './routes/SpecialOfferLanding';
import { SetToast } from './actions/toaster';
import AdminModeControls from './components/account/AdminModeControls';
import { isDelinquent } from './lib/subscriptions';
import { SpecialOffers } from './components/specialOffers/SpecialOffers';
import EventBanner from './components/specialOffers/EventBanner';
import UpdateTeamPaymentMethod from './components/payment/UpdateTeamPaymentMethod';
import { canUserChangeCurrency } from './util/util';
import { guessCurrency } from './util/conversions';
import { State } from './store';
import WrenForBusinessBanner from './components/specialOffers/WrenForBusinessBanner';
import BlogPost from './routes/BlogPost';
import CaseStudy from './routes/Teams/CaseStudy';

const ReferralDialogLazy = lazy(() => import('./components/ReferralDialog'));
const ReferralDialog = () => (
  <Suspense fallback={null}>
    <ReferralDialogLazy />
  </Suspense>
);

// interfaces that can be loaded in later
const DonationReceipt = lazy(() => import('./routes/DonationReceipt'));
const ImpactCertificate = lazy(() => import('./routes/ImpactCertificate'));
const SubscriptionSuccess = lazy(() => import('./routes/SubscriptionSuccess'));
const IntroSurvey = lazy(() => import('./routes/IntroSurvey'));
const AboutOffsets = lazy(() => import('./routes/AboutOffsets'));
const Certification = lazy(() => import('./routes/Certification'));
const AboutUs = lazy(() => import('./routes/AboutUs'));
const AccountSettings = lazy(() => import('./routes/AccountSettings'));
const Actions = lazy(() => import('./routes/Actions'));
const AllBlogPosts = lazy(() => import('./routes/AllBlogPosts'));
const Calculator = lazy(() => import('./routes/Calculator'));
const Careers = lazy(() => import('./routes/Careers'));
const Checkout = lazy(() => import('./routes/Checkout'));
const Crypto = lazy(() => import('./routes/Crypto'));
const Dashboard = lazy(() => import('./routes/DashboardV2'));
const DeleteAccountPage = lazy(() => import('./routes/DeleteAccountPage'));
const Emails = lazy(() => import('./routes/Emails'));
const FAQ = lazy(() => import('./routes/FAQ'));
const FB = lazy(() => import('./routes/FB'));
const Flights = lazy(() => import('./routes/Flights'));
const FundraiserDonatePage = lazy(() => import('./routes/Fundraisers/Donate')); // prettier-ignore
const FundraiserPage = lazy(() => import('./routes/Fundraisers/FundraiserPage')); // prettier-ignore
const FundraisersEdit = lazy(() => import('./routes/Fundraisers/Edit'));
const FundraisersNew = lazy(() => import('./routes/Fundraisers/New'));
const FundraiserSuccess = lazy(() => import('./routes/Fundraisers/Success'));
const Gifts = lazy(() => import('./routes/Gifts'));
const Group = lazy(() => import('./routes/Groups/Group'));
const GroupAccept = lazy(() => import('./routes/Groups/GroupAccept'));
const GroupInvite = lazy(() => import('./routes/Groups/GroupInvite'));
const GroupsLanding = lazy(() => import('./routes/Groups/Landing'));
const JobPost = lazy(() => import('./routes/JobPost'));
const Leaderboards = lazy(() => import('./routes/Leaderboards'));
const Login = lazy(() => import('./routes/Login'));
const OffsetAnything = lazy(() => import('./routes/OffsetAnythingV2'));
const OffsetOrders = lazy(() => import('./routes/OffsetOrders'));
const OurApproach = lazy(() => import('./routes/OurApproach'));
const OurProjects = lazy(() => import('./routes/Projects'));
const PayItForward = lazy(() => import('./routes/PayItForward'));
const PayItForwardSuccess = lazy(() => import('./routes/PayItForwardSuccess'));
const PayWhatYouWant = lazy(() => import('./routes/PayWhatYouWant'));
const PortfolioPage = lazy(() => import('./routes/Portfolios'));
const Profile = lazy(() => import('./routes/Profile'));
const ProjectPage = lazy(() => import('./components/projects/ProjectPage'));
const ProjectUpdatePost = lazy(() => import('./routes/ProjectUpdatePost'));
const PublicFootprint = lazy(() => import('./routes/PublicFootprint'));
const ReportAndChoosePlan = lazy(() => import('./routes/ReportAndChoosePlan'));
const Teams = lazy(() => import('./routes/Teams'));
const UpgradeSubscription = lazy(() => import('./routes/UpgradeSubscription'));
const WrenAPI = lazy(() => import('./routes/WrenAPI'));
const ShopifyPublicProfile = lazy(
  () => import('./routes/shopify/PublicProfile')
);
const ShopifyOnboarding = lazy(() => import('./routes/shopify/Onboarding'));
const SwitchToTrailblazer = lazy(
  () => import('./routes/AccountSettings/SwitchToTrailblazer')
);
const HNWLandingPage = lazy(() => import('./routes/HNW'));
const HNWDonate = lazy(() => import('./routes/HNW/Donate'));
const BirdseyeLanding = lazy(() => import('./routes/BirdseyeLanding'));
const Donate = lazy(() => import('./routes/DonorPortal/Donate'));
const DonateSuccess = lazy(() => import('./routes/DonorPortal/Success'));

export const PathHistoryContext = createContext<string[]>([]);

function AccountsOnly({ children }: PropsWithChildren<unknown>) {
  const user = useSelector((state: State) => state.user);
  const history = useHistory();
  const { location } = history;
  if (user.hasPassword) {
    return <>{children}</>;
  }
  history.replace(
    `/login?forward=${encodeURIComponent(
      location.pathname + location.search + location.hash
    )}`
  );
  return null;
}

function NonOnboardedUsersOnly({ children }: PropsWithChildren<unknown>) {
  const user = useSelector((state: State) => state.user);
  const history = useHistory();
  if (user.completedOnboarding) {
    history.replace('/dashboard');
    return null;
  }
  return <>{children}</>;
}

function PageContent() {
  const user = useSelector((state: State) => state.user);

  const dispatch = useDispatch();
  const location = useLocation();
  const routeHasNoNav = useRouteMatch(NavConfig.routesWithNoNav as string[]);
  const hideNav = routeHasNoNav?.isExact;
  const pathHistory = usePathHistory();
  const { getQueryParam } = useQueryParams();

  const specialOfferToken = getQueryParam('so');
  const toastFromUrl = getQueryParam('toast');

  const pagesWithoutSubscriptionChurnNotice = [
    '/settings',
    '/success',
    '/update-payment',
    '/pwyw',
    '/goodbye!',
    '/checkout/payment',
  ];

  useEffect(() => {
    if (specialOfferToken) {
      dispatch(ClaimSpecialOffer(specialOfferToken));
    }
  }, [specialOfferToken]);

  useEffect(() => {
    if (toastFromUrl) {
      dispatch(SetToast({ text: toastFromUrl }));
    }
  }, [toastFromUrl]);

  useEffect(() => {
    if (user && canUserChangeCurrency(user) && !user.currency) {
      const currencyCode = guessCurrency(user.country || 'US');
      dispatch(UpdateCurrency(currencyCode));
    }
  }, [user]);

  const hasDelinquentPayment = isDelinquent(user.status);

  const flow = useOnboardingFlow();

  const isOnboardingRoute =
    (location.pathname !== '/' &&
      flow.getOnboardingSteps().includes(location.pathname as Step)) ||
    location.pathname.includes('/intro'); // helps exclude all intro questions

  const canShowBanner =
    location.pathname !== '/groups/join' &&
    (user.groups.length === 0 ||
      (!isOnboardingRoute && user.groups.length > 0)) &&
    !user.completedOnboarding;

  const showWebinarBanner = false;

  const showWrenForBusinessBanner =
    location.pathname === '/' &&
    user.specialOfferClaims.length === 0 &&
    !showWebinarBanner;

  // Until end of July 2023, show the event banner for landing page visitors to contribue to our Mangrove Conservation fundraiser.
  const showEventBanner =
    new Date().getTime() <= new Date('2023-11-15T18:00:00Z').getTime() &&
    (location.pathname === '/teams/business' ||
      location.pathname === '/teams/signup') &&
    user.specialOfferClaims.length === 0;

  return (
    <PathHistoryContext.Provider value={pathHistory}>
      <div css={{ position: 'inherit', minHeight: '100vh' }}>
        <HashLinkObserver />
        <ReferralDialog />
        <AdminModeControls />
        {hasDelinquentPayment &&
          !pagesWithoutSubscriptionChurnNotice.includes(location.pathname) && (
            <SubscriptionChurnNotice />
          )}
        {showEventBanner && <EventBanner />}
        {showWrenForBusinessBanner && <WrenForBusinessBanner />}
        {canShowBanner && <SpecialOffers user={user} />}
        {isOnboardingRoute &&
          user.groups.length > 0 &&
          // @ts-expect-error: translate to TS (group prop is optional)
          !user.completedOnboarding && <GroupCallout.Banner />}
        {!hideNav && <Nav />}
        {location.search.includes('bonus_type') && <BonusIncentive />}
        <Suspense
          fallback={
            <SuspenseFallback
              show
              delay={500}
              width={140}
              css={{ marginTop: 150 }}
            />
          }
        >
          <Switch>
            {/* FRONTFACING ONE OFF */}
            <Route exact path="/fb" children={<FB />} />
            <Route exact path="/pwyw" children={<PayWhatYouWant />} />
            <Route exact path="/pay-it-forward" children={<PayItForward />} />
            <Route
              exact
              path="/pif-success"
              children={<PayItForwardSuccess />}
            />
            <Route
              exact
              path="/join/:referralUsername"
              children={<ReferralLanding />}
            />
            {/* <Route
              exact
              path="/community-challenge/plant-based-week-2024"
              children={<CommunityChallenge />}
            /> */}
            <Route path="/wren-api" children={<WrenAPI />} />
            <Redirect exact path="/how-it-works" to="/our-approach" />
            <Route exact path="/charter" children={<UpdatedCharter />} />
            <Route exact path="/about-us" children={<AboutUs />} />
            <Route exact path="/offsets" children={<AboutOffsets />} />
            <Route path="/certification" children={<Certification />} />
            <Redirect from="/about" to="/about-us" />
            <Route exact path="/faq" children={<FAQ />} />
            <Route exact path="/offsets" children={<OffsetOrders />} />
            <Route exact path="/impact" children={<OffsetOrders />} />
            <Route exact path="/leaderboard" children={<Leaderboards />} />
            <Route exact path="/our-approach" children={<OurApproach />} />
            <Route exact path="/giving-tuesday">
              <Redirect
                to={`/fundraisers/3c2d37bf-de29-405c-94aa-f156c0437d7b`}
              />
            </Route>
            <Route exact path="/give">
              <Redirect to={`/giving-tuesday`} />
            </Route>
            {/* FRONTFACING WITH SUBPAGES */}
            <Route exact path="/careers" children={<Careers />} />
            <Route exact path="/careers/:link" children={<JobPost />} />
            <Route path="/projects/wren-climate-fund">
              <Redirect to="/portfolios/wren-climate-fund" />
            </Route>
            <Route path="/projects/regenerative-agroforestry">
              <Redirect to="/projects/enhanced-mineral-weathering" />
            </Route>
            <Route path="/projects/weforest">
              <Redirect to="/blog/tree-growing" />
            </Route>
            <Route path="/projects/mineral-weathering-in-scotland">
              <Redirect to="/projects/enhanced-mineral-weathering" />
            </Route>
            <Route path="/projects/biochar-in-california">
              <Redirect to="/projects/biochar-carbon-removal" />
            </Route>
            <Route path="/projects/:slug" children={<ProjectPage />} />
            <Route exact path="/projects" children={<OurProjects />} />
            <Route path="/trailblazer">
              <Redirect to="/portfolios/trailblazer" />
            </Route>
            <Route path="/biochar">
              <Redirect to="/blog/pacific-biochar-investment" />
            </Route>
            <Route path="/slingshot">
              <Redirect to="/fundraisers/3c2d37bf-de29-405c-94aa-f156c0437d7b" />
            </Route>
            <Route path="/classic">
              <Redirect to="/portfolios/universal-fund" />
            </Route>
            <Route path="/portfolios/classic">
              <Redirect to="/portfolios/universal-fund" />
            </Route>
            <Route path="/portfolios/:slug" children={<PortfolioPage />} />
            <Route path="/offset-anything" children={<OffsetAnything />} />
            <Route path="/crypto" children={<Crypto />} />
            {/* GROUPS */}
            <Route path="/groups/accept" children={<GroupAccept />} />
            <Route path="/groups/join" children={<GroupInvite />} />
            <Route path="/groups/:slug" children={<Group />} />
            <Route path="/groups" children={<GroupsLanding />} />
            {/* BLOG POSTS */}
            <Route path="/updates/:slug" children={<ProjectUpdatePost />} />
            <Route path="/updates">
              <Redirect to="/blog" />
            </Route>
            <Route exact path="/blog" children={<AllBlogPosts />} />
            <Route
              path="/blog/posts/:slug?"
              render={({ match }) => (
                <Redirect to={`/blog/${match.params.slug ?? ''}`} />
              )}
            />
            <Route path="/blog/:slug" children={<BlogPost />} />
            {/* ONBOARDING */}
            <Route path="/calculator/submit">
              <Redirect to="/choose-plan" />
            </Route>
            <Route path="/calculator" children={<Calculator />} />
            <Route path="/choose-project">
              <Redirect to="/choose-plan" />
            </Route>
            <Route
              exact
              path="/choose-plan"
              children={
                <NonOnboardedUsersOnly>
                  <ReportAndChoosePlan />
                </NonOnboardedUsersOnly>
              }
            />
            <Route exact path="/continue" children={<ContinueOnboarding />} />
            <Route path="/checkout" children={<Checkout />} />
            <Route path="/intro/:questionSlug" children={<IntroSurvey />} />
            {/* ACCOUNT */}
            <Route exact path="/invite">
              <Redirect to="/dashboard/reach" />
            </Route>
            <Route path="/start/:token" children={<SpecialOfferLanding />} />
            <Route path="/profile/:username" children={<Profile />} />
            {/* For specific open graph images */}
            <Route
              path="/profile/:username/og/:opengraph"
              children={<Profile />}
            />
            {/* ACTIONS */}
            {/* moved canonically */}
            {/* redirect not-logged-in users away from dashboard/actions for convenience/sharing */}
            {!user.hasPassword && (
              <Route path="/dashboard/actions">
                <Redirect to="/actions" />
              </Route>
            )}
            <Route path="/actions" children={<Actions />} />
            {/* Old dashboard links */}
            <Route
              path="/dashboard/settings/:anything?"
              render={({ match }) => (
                <Redirect to={`/settings/${match.params.anything ?? ''}`} />
              )}
            />
            <Route exact path="/dashboard/funding/past-emissions">
              <Redirect to="/dashboard" /* to timeline */ />
            </Route>
            <Route exact path="/dashboard/funding/historical">
              <Redirect to="/dashboard" /* to timeline */ />
            </Route>
            <Route exact path="/dashboard/offsets/past-emissions">
              <Redirect to="/dashboard" /* to timeline */ />
            </Route>
            <Route
              path="/dashboard/offsets/:anything?"
              render={({ match }) => (
                <Redirect
                  to={`/dashboard/funding/${match.params.anything ?? ''}`}
                />
              )}
            />
            <Route
              path="/dashboard/invite/:anything?"
              render={({ match }) => (
                <Redirect to={`/settings/${match.params.anything ?? ''}`} />
              )}
            />
            <Route
              path="/dashboard"
              children={
                <AccountsOnly>
                  <Dashboard />
                </AccountsOnly>
              }
            />
            <Route exact path="/delete" children={<DeleteAccountPage />} />
            <Route
              path="/settings/trailblazer"
              children={
                <AccountsOnly>
                  <SwitchToTrailblazer />
                </AccountsOnly>
              }
            />
            <Route
              path="/settings"
              children={
                <AccountsOnly>
                  <AccountSettings />
                </AccountsOnly>
              }
            />
            <Route path="/subscription">
              {/* For convenience and short sms urls */}
              <Redirect to="/settings/subscription" />
            </Route>
            <Route
              exact
              path="/public-footprint/:username"
              children={<PublicFootprint />}
            />
            <Route
              exact
              path="/footprint/:username"
              children={<PublicFootprint />}
            />
            <Route exact path="/login" children={<Login />} />
            <Route exact path="/logout" children={<LogOut />} />
            <Route
              exact
              path="/forgot-password"
              children={<ForgotPassword />}
            />
            <Route exact path="/reset" children={<RequestPassReset />} />
            <Route exact path="/confirm-email" children={<ConfirmEmail />} />
            <Route exact path="/update-payment" children={<UpdatePayment />} />
            <Route
              exact
              path="/update-team-payment"
              children={<UpdateTeamPaymentMethod />}
            />
            {/* FLIGHTS */}
            <Route path="/flights" children={<Flights />} />
            {/* TEAMS */}
            <Route path="/teams" children={<Teams />} />
            <Route path="/benefit">
              <Redirect to="/teams" />
            </Route>
            <Route path="/business/jameo-case-study" children={<CaseStudy />} />
            <Route path="/business">
              <Redirect to="/teams/business" />
            </Route>
            {/* GIFTS */}
            <Route path="/gifts" children={<Gifts />} />
            <Route path="/give-wren">
              <Redirect to="/gifts" />
            </Route>
            <Route
              path="/gift/:giftId"
              render={({ match }) => (
                <Redirect to={`/gifts/receive/${match.params.giftId}`} />
              )}
            />
            {/* SOME MORE PROTECTED ROUTES */}
            <Route
              exact
              path="/success"
              children={
                <AccountsOnly>
                  <SubscriptionSuccess />
                </AccountsOnly>
              }
            />
            <Route
              exact
              path="/upgrade"
              children={
                <AccountsOnly>
                  <UpgradeSubscription />
                </AccountsOnly>
              }
            />
            <Route
              exact
              path="/fundraisers/new"
              children={<FundraisersNew />}
            />
            <Route
              exact
              path="/fundraisers/:id"
              children={<FundraiserPage />}
            />
            <Route exact path="/receipt/:id" children={<DonationReceipt />} />
            <Route
              exact
              path="/certificate/:id"
              children={<ImpactCertificate />}
            />
            <Route
              exact
              path="/fundraisers/:id/edit"
              children={<FundraisersEdit />}
            />
            <Route
              exact
              path="/fundraisers/:id/success"
              children={<FundraiserSuccess />}
            />
            <Route
              exact
              path="/fundraisers/:id/donate"
              children={<FundraiserDonatePage />}
            />
            {/* MISC */}
            <Route exact path="/private" children={<Private />} />
            <Route exact path="/goodbye!" children={<CancellationMessage />} />
            <Route path="/emails" children={<Emails />} />
            <Route
              path="/donate/:portfolioSlug/success"
              exact
              children={
                <AccountsOnly>
                  <DonateSuccess />
                </AccountsOnly>
              }
            ></Route>
            <Route
              path="/donate/:portfolioSlug"
              exact
              children={<Donate />}
            ></Route>
            <Route path="/email">
              <Redirect to="/" />
            </Route>
            <Route path="/youtube">
              <Redirect to="/" />
            </Route>
            {/* One-off holiday thing */}
            <Route path="/waigifts">
              <Redirect to="/gifts" />
            </Route>
            <Route path="/shop/profile/:username">
              <ShopifyPublicProfile />
            </Route>
            <Route path="/shop">
              <ShopifyOnboarding />
            </Route>
            <Route path="/reductions">
              <Redirect to="/actions" />
            </Route>
            <Route path="/subscription">
              <Redirect to="/continue" />
            </Route>
            <Route path="/reports">
              <Redirect to="/updates" />
            </Route>
            <Route path="/philanthropy">
              <Redirect to="/collective" />
            </Route>
            <Route path="/collective/donate" exact children={<HNWDonate />} />
            <Route path="/collective" children={<HNWLandingPage />} />
            <Route path="/birdseye" children={<BirdseyeLanding />} />\
            {/* NOT FOUND: */}
            <Route exact path="/404" children={<NotFound />} />
            <Route children={<NotFound />} />
          </Switch>
        </Suspense>
        <Toaster />
        <Global styles={GlobalStyles} />
      </div>
    </PathHistoryContext.Provider>
  );
}

export default PageContent;
