import { convertCurrency, getCurrencyObjFromCode } from '../util/conversions';
import { CurrencyOptions } from './format';

export function formatCurrency(
  amount: number,
  {
    displayCurrency,
    amountCurrency,
    maximumFractionDigits = 2,
    fromLowestDenominator,
    abbreviate = false,
  }: CurrencyOptions
) {
  const displayCurrencyObj = getCurrencyObjFromCode(displayCurrency);

  let displayAmount = amount;

  if (fromLowestDenominator) {
    displayAmount = amount / displayCurrencyObj.smallestDenominationDivider;
  }

  if (amountCurrency !== displayCurrency) {
    displayAmount = convertCurrency(
      displayAmount,
      amountCurrency,
      displayCurrencyObj.code
    );
  }

  return Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: displayCurrencyObj.code,
    notation: abbreviate ? 'compact' : 'standard', // Switch between compact and standard
    maximumFractionDigits:
      displayCurrencyObj.code === 'JPY' ? 0 : maximumFractionDigits,
    minimumFractionDigits:
      displayCurrencyObj.code === 'JPY' ? 0 : maximumFractionDigits,
  }).format(displayAmount);
}
