/** @jsxImportSource @emotion/react */
import { HTMLAttributes } from 'react';
import { CO2 } from '../components/svgs/CO2';
import { Calculator as CalculatorIcon } from '../components/svgs/Calculator';
import { Certification } from '../components/svgs/Certification';
import { FAQ } from '../components/svgs/FAQ';
import { Forest } from '../components/svgs/Forest';
import { Fundraiser } from '../components/svgs/Fundraiser';
import Giftbox from '../components/svgs/Giftbox';
import { Leaderboard } from '../components/svgs/Leaderboard';
import { Plane } from '../components/svgs/Plane';
import { Pulse } from '../components/svgs/Pulse';
import { Saucer } from '../components/svgs/Saucer';
import { Sliders } from '../components/svgs/Sliders';
import Stopwatch from '../components/svgs/Stopwatch';
import { TrailblazerLogo } from '../components/svgs/TrailblazerLogo';
import { WrenHeart } from '../components/svgs/WrenHeart';
import WrenLogo from '../components/svgs/WrenLogo';
import { Svgs, categoryColors } from '../routes/DashboardV2/CategoryIcon';
import { colors } from '../style/theme';
import { SerializedUser } from '../types';
import { DEFAULT_PORTFOLIO_NAME } from './constants';
import businessProducts from '../routes/Teams/BusinessProducts';
import { NEWEST_FINANCIAL_REPORT_URL } from '../config/constants';

export type DropdownLink = {
  to: string;
  name: string;
  description?: string;
  isNew?: boolean;
  hash?: string;
  icon?: {
    backgroundColor: string;
    component: React.ReactElement<
      { color?: string } & HTMLAttributes<SVGElement>
    >;
  };
} | null;

export interface DropdownSection {
  title?: string;
  links: DropdownLink[];
}

export const product: (user: SerializedUser) => DropdownSection[] = (user) => [
  {
    title: 'What you can do',
    links: [
      user.completedOnboarding && !user.team
        ? {
            to: '/settings/subscription',
            name: 'Manage subscription',
          }
        : { to: '/calculator', name: 'Monthly subscription' },
      { to: '/groups', name: 'Groups ', isNew: true },
      { to: '/offset-anything', name: 'Offset anything' },
      { to: '/crypto', name: 'Crypto Offsets' },
      { to: '/flights', name: 'Flights' },
      { to: '/gifts', name: 'Gifts' },
    ],
  },
  {
    links: [
      {
        name: 'API ',
        to: '/wren-api',
      },
      { to: '/teams/business', name: 'Wren for Companies' },
      {
        to: 'https://www.notion.so/projectwren/f39023cc81034d2ca64d1fa184e685ad?v=80ee68408a4a4015a1d471485b860504',
        name: 'Roadmap',
      },
    ],
  },
];

export const projects = [
  // Hiding for now
  {
    links: [
      {
        to: '/projects#portfolio',
        name: `🌍 The ${DEFAULT_PORTFOLIO_NAME}`,
      },
    ],
  },
  {
    links: [
      {
        to: '/projects/biochar-carbon-removal',
        name: '🪨 Biochar carbon removal',
      },
      {
        to: '/projects/enhanced-mineral-weathering',
        name: '🌧 Enhanced mineral weathering',
        isNew: true,
      },
      {
        to: '/projects/community-tree-planting',
        name: '🌲 Community tree planting',
      },
      {
        to: '/projects/clean-cooking-fuel',
        name: '🔥 Clean cooking fuel for refugees',
      },
      {
        to: '/projects/tech-enabled-rainforest-protection',
        name: '🛰 Tech-enabled rainforest protection',
      },
    ],
  },
];

export const products = (user?: SerializedUser) => [
  {
    title: 'Products',
    links: [
      {
        to: user?.completedOnboarding ? '/settings/subscription' : '/continue',
        name: 'Wren subscription',
        description: 'Recurrently fund climate projects.',
        icon: {
          backgroundColor: colors.wrenGreenLowOpacity,
          component: <WrenLogo fill={colors.wrenGreen} width={26} />,
        },
      },
      {
        to: '/calculator',
        name: 'Carbon calculator',
        description: 'Understand your footprint.',
        icon: {
          backgroundColor: colors.wrenOrangeLowOpacity,
          component: <CalculatorIcon size={26} />,
        },
      },
      {
        to: '/business',
        name: 'Business',
        description: 'Explore Wren’s business offerings.',
        isNew: true,
        icon: {
          backgroundColor: categoryColors.team.background,
          component: <Svgs.team size={30} fill={categoryColors.team.main} />,
        },
      },
      {
        to: '/offset-anything',
        name: 'Offset anything',
        isNew: true,
        description: 'Offset any amount of carbon.',
        icon: {
          backgroundColor: 'rgba(91, 39, 240, 0.1)',
          component: <Saucer size={30} />,
        },
      },
      {
        to: '/dashboard/actions',
        name: 'Wren Actions',
        description: 'Browse curated climate actions.',
        icon: {
          backgroundColor: colors.successGreenLowOpacity,
          component: <Svgs.actions size={26} fill={colors.successGreen} />,
        },
      },
      {
        to: '/flights',
        name: 'Flights',
        description: 'Log flights and offset them.',
        icon: {
          backgroundColor: 'rgba(50, 123, 179, 0.1)',
          component: <Plane size={30} />,
        },
      },
      {
        to: '/fundraisers/new',
        name: 'Fundraisers',
        description: 'Raise money for climate projects.',
        icon: {
          backgroundColor: 'rgba(206, 127, 216, 0.1)',
          component: <Fundraiser size={30} />,
        },
      },

      {
        to: '/gifts',
        name: 'Gifts',
        description: 'Gift climate impact to loved ones.',
        icon: {
          backgroundColor: 'rgba(219, 63, 64, 0.1)',
          component: <Giftbox width={26} color={colors.errorRed} />,
        },
      },
    ],
  },
];

const solutions = businessProducts.map((product) => ({
  to: `/teams/business#${product.pageAnchorTag}`,
  name: product.name,
  description: '',
  icon: {
    backgroundColor: product.colors.secondary,
    component: product.icon,
  },
}));

export const businessSolutions = [
  {
    title: 'Business solutions',
    links: solutions,
  },
];

export const resources: DropdownSection[] = [
  {
    title: 'Resources',
    links: [
      {
        to: '/projects',
        name: 'Our projects',
        description: 'What we’re funding now.',
        icon: {
          backgroundColor: colors.wrenGreenLowOpacity,
          component: <Forest size={26} />,
        },
      },
      {
        to: '/about-us',
        name: 'About us',
        description: 'Our team and backstory.',
        icon: {
          backgroundColor: colors.wrenGreenLowOpacity,
          component: <WrenHeart size={30} />,
        },
      },
      {
        to: '/updates',
        name: 'Project updates',
        description: 'The latest from our projects.',
        icon: {
          backgroundColor: colors.wrenGreenLowOpacity,
          component: <Pulse size={26} />,
        },
      },
      {
        to: '/offsets',
        name: 'Our view on offsets',
        description: 'The do’s and dont’s of offsets.',
        icon: {
          backgroundColor: colors.wrenGreenLowOpacity,
          component: <CO2 width={32} height={32} />,
        },
      },
      {
        to: '/our-approach',
        name: 'Our approach',
        description: 'How we think about Wren.',
        icon: {
          backgroundColor: colors.wrenGreenLowOpacity,
          component: <Sliders size={26} />,
        },
      },
      {
        to: '/leaderboard',
        name: 'Leaderboard',
        description: 'See who’s on top.',
        icon: {
          backgroundColor: colors.wrenGreenLowOpacity,
          component: <Leaderboard size={26} />,
        },
      },
      {
        to: '/certification',
        name: 'Project certification',
        description: 'How Wren thinks about impact.',
        icon: {
          backgroundColor: colors.wrenGreenLowOpacity,
          component: <Certification size={26} />,
        },
      },
      {
        to: '/faq',
        name: 'FAQs',
        description: 'Your questions, answered.',
        icon: {
          backgroundColor: colors.wrenGreenLowOpacity,
          component: <FAQ size={26} />,
        },
      },
    ],
  },
];

export const about = [
  {
    links: [
      { to: '/about-us', name: 'About us' },
      { to: '/our-approach', name: 'Our approach' },
      { to: '/projects', name: 'Our projects' },
      { to: '/leaderboard', name: 'Leaderboard' },
      { to: '/faq', name: 'FAQ' },
    ],
  },
  {
    title: 'Learn more',
    links: [
      {
        to: '/updates',
        name: 'Project updates',
      },
      {
        to: '/offsets',
        name: 'Our view on offsets',
      },
      {
        to: '/certification',
        name: 'Project certification',
      },
      {
        to: 'https://www.wren.co/blog/posts/why-wren-is-a-public-benefit-corporation',
        name: 'Why Wren is a PBC',
      },
      {
        to: NEWEST_FINANCIAL_REPORT_URL,
        name: 'Latest financial report',
      },
    ],
  },
];

export const teamOwner = (user: SerializedUser) => [
  {
    links: [
      user.ownedTeams.map((team) => [
        {
          to: `/teams/${team.id}/manage`,
          name: `Manage ${team.name}`,
        },
        {
          to: `/teams/${team.id}`,
          name: `${team.name} Impact`,
        },
      ]),
    ].flat(2),
  },
  {
    links: [{ to: '/logout', name: 'Logout' }],
  },
];

export const groups = (user: SerializedUser) => [
  {
    links: user.groups.map((group) => ({
      to: `/groups/${group.slug}`,
      name: group.name,
    })),
  },
];

export const offsets: DropdownSection[] = [
  {
    title: 'Offsets',
    links: [
      {
        to: '/calculator',
        name: 'Wren subscription',
        description: 'Modify your subscription.',
        icon: {
          backgroundColor: colors.wrenGreenLowOpacity,
          component: <WrenLogo fill={colors.wrenGreen} width={26} />,
        },
      },
      {
        to: '/offset-anything?portfolio=trailblazer',
        name: 'Carbon removal',
        description: 'Purchase high-tech removal',
        isNew: true,
        icon: {
          backgroundColor: colors.trailblazerOrangeLowOpacity,
          component: <TrailblazerLogo size={30} />,
        },
      },
      {
        to: '/gifts',
        name: 'Gifts',
        description: 'Gift climate impact to loved ones.',
        icon: {
          backgroundColor: 'rgba(219, 63, 64, 0.1)',
          component: <Giftbox width={26} color={colors.errorRed} />,
        },
      },
      {
        to: '/offset-anything',
        name: 'Offset anything',
        isNew: true,
        description: 'Offset any amount of carbon.',
        icon: {
          backgroundColor: 'rgba(91, 39, 240, 0.1)',
          component: <Saucer size={30} />,
        },
      },
      {
        to: '/flights',
        name: 'Flights',
        description: 'Log flights and offset them.',
        icon: {
          backgroundColor: 'rgba(50, 123, 179, 0.1)',
          component: <Plane size={30} />,
        },
      },
      {
        to: '/dashboard#timeline',
        name: 'Historical emissions',
        description: 'Visualize and offset past emissions.',
        icon: {
          backgroundColor: colors.wrenOrangeLowOpacity,
          component: <Stopwatch size={30} fill={colors.wrenOrange} />,
        },
      },
      {
        to: '/fundraisers/new',
        name: 'Fundraisers',
        description: 'Raise money for climate projects.',
        icon: {
          backgroundColor: 'rgba(206, 127, 216, 0.1)',
          component: <Fundraiser size={30} />,
        },
      },
      {
        to: 'mailto:support@wren.co?subject=Request a feature',
        name: 'Request a feature',
        description: 'What else would you like?',
        icon: {
          backgroundColor: 'rgba(51, 51, 51, 0.05)',
          component: <div css={{ fontSize: 32, height: '100%' }}>+</div>,
        },
      },
    ],
  },
];

export const profile: (user: SerializedUser) => DropdownSection[] = (user) => {
  const groups = user.groups;
  const fundraisers = user.fundraisers;

  return [
    ...(user.team || (user.ownedTeams && user.ownedTeams.length > 0)
      ? [
          {
            links: [
              user.team && {
                to: `/teams/${user.team.id}`,
                name: `My team's impact`,
              },
              user.ownedTeams.map((team) => [
                {
                  to: `/teams/${team.id}/manage`,
                  name: `Manage ${team.name}`,
                },
              ]),
            ].flat(2),
          },
        ]
      : []),
    {
      links: [
        { to: `/dashboard`, name: 'Dashboard' },
        { to: `/calculator`, name: 'Calculator' },
        { to: `/profile/${user.username}`, name: 'Public Profile' },
        { to: '/settings', name: 'Settings' },
        ...(user.completedOnboarding && !user.team
          ? [
              {
                to: '/settings/subscription',
                name: 'Manage subscription',
              },
            ]
          : []),
      ],
    },
    ...(groups.length > 0
      ? [
          {
            title: 'Your Groups',
            links: groups.map((group) => ({
              to: `/groups/${group.slug}`,
              name: group.name,
            })),
          },
        ]
      : []),
    ...(fundraisers.length > 0
      ? [
          {
            title: 'Fundraisers',
            links: fundraisers.map((fundraiser) => ({
              to: `/fundraisers/${fundraiser.id}`,
              name: fundraiser.title,
            })),
          },
        ]
      : []),
    {
      links: [{ to: '/logout', name: 'Logout' }],
    },
  ];
};
