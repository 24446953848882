/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { HTMLAttributes, PropsWithChildren } from 'react';
import { colors, fonts, spacing } from '../../style/theme';
import { StandardProperties } from 'csstype';

const getStyle = (
  color: string,
  listStyleType?: StandardProperties['listStyleType']
) =>
  css({
    counterReset: 'item',
    listStyle: 'none',
    paddingInlineStart: spacing.medium,
    '& > li': {
      counterIncrement: 'item',
      listStyle: 'none',
      marginTop: spacing.medium,
      paddingLeft: spacing.default,
      position: 'relative',
      '&::before': {
        color,
        content: `counter(item, ${listStyleType})`,
        fontFamily: fonts.Sans,
        fontWeight: 700,
        right: '100%',
        position: 'absolute',
      },
      '&:first-of-type': {
        marginTop: 0,
      },
    },
  });

export default function OrderedList({
  color = colors.wrenOrange,
  listStyleType = 'decimal',
  ...restOfProps
}: PropsWithChildren<
  {
    color?: string;
    listStyleType?: StandardProperties['listStyleType'];
  } & HTMLAttributes<HTMLOListElement>
>) {
  return <ol css={getStyle(color, listStyleType)} {...restOfProps} />;
}
