/** @jsxImportSource @emotion/react */
import { scale } from '../../util/scale';
import HoverArrow from '../svgs/HoverArrow';
import { isMobile } from '../../lib/platform';
import Flex from '../UI/Flex';
import Link from '../UI/Link';

import { SerializedUser, SpecialOffer } from '../../types';
import { colors, spacing } from '../../style/theme';
import { SpecialOfferTokensThatBetterBeInTheDb } from '../../config/constants';

const topAnnouncement = (color = 'white') =>
  scale({
    textAlign: 'center',
    backgroundColor: color,
    color: '#333',
    position: 'relative',
    boxSizing: 'border-box',
    padding: '14px 8px',
    borderBottom: `2px solid ${colors.textPrimary}`,
  });

/* Displays various offers as a banner/pop-up */
export function SpecialOffers({ user }: { user: SerializedUser }) {
  const specialOffer: SpecialOffer | undefined =
    user.specialOfferClaims[0]?.specialOffer;

  const showKurzgesagtDECustomBanner = user.specialOfferClaims?.some(
    (claim) => claim.specialOffer.token === 'kurzgesagtDE'
  );

  if (!specialOffer) {
    return null;
  }

  function getMessage() {
    if (!specialOffer) {
      return null;
    }

    if (specialOffer.incentiveType !== 'covered_months') {
      return <p>Subscribe and Wren will {specialOffer.incentiveDescription}</p>;
    }

    const { referrer } = user;

    const isReferral =
      specialOffer.token ===
        SpecialOfferTokensThatBetterBeInTheDb.GROUP_AND_REFERRAL_BONUS &&
      referrer;

    if (isReferral) {
      return (
        <p>
          <b>
            {`${referrer.firstName || 'Your friend'} is inviting  you to Wren`}
          </b>
          <br />
          Subscribe and your first month is free!
        </p>
      );
    }

    return (
      <>
        <p>
          {specialOffer.partnerName && (
            <b css={{ marginRight: '4px ' }}>{specialOffer.partnerName}:</b>
          )}
          Your first month of Wren is free!
        </p>
        {!isMobile() && (
          <HoverArrow isHovering width={10} css={{ marginLeft: 8 }} />
        )}
      </>
    );
  }

  return (
    <>
      {showKurzgesagtDECustomBanner && (
        <Link to="/continue" css={{ textDecoration: 'none', fontWeight: 400 }}>
          <Flex css={topAnnouncement()} align="center" justify="center">
            <p
              css={scale({
                fontSize: [12, 15, 16],
                padding: [0, 0, spacing.xxSmall],
                lineHeight: 1.4,
              })}
            >
              <strong>Hallo Kurzgesagt Zuschauer:</strong> Schließen Sie sich
              Tausenden deutschen Mitgliedern an, die gegen den Klimawandel
              kämpfen 🇩🇪
            </p>
          </Flex>
        </Link>
      )}
      {/* Free month experiment supersedes the normal promo banner */}
      {specialOffer && !showKurzgesagtDECustomBanner && (
        <Link to="/continue" css={{ textDecoration: 'none', fontWeight: 500 }}>
          <Flex css={topAnnouncement()} align="center" justify="center">
            {getMessage()}
          </Flex>
        </Link>
      )}
    </>
  );
}
