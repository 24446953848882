import { isMobile } from '../lib/platform';

// NOTE: The order matters when listing paths in these arrays.
// For instance:
// If you have parent route with sub routes, place subroute
// BEFORE parent routes in array:
//   '/gifts/:giftId' goes above '/gifts'

export const routesWithNoNav = [
  '/groups/join',
  '/groups/accept',
  '/choose-plan/:slug',
  isMobile() && '/checkout',
  '/goodbye!',
  '/quiz/results',
  '/quiz/',
  '/update/:portfolioId/:name',
  isMobile() && '/funds/:id/donate',
  isMobile() && '/funds/:id/tip',
  isMobile() && '/funds/:id/checkout',
  '/pay-it-forward',
  '/collective/donate',
  '/collective',
  '/receipt/:id',
  '/birdseye',
  '/donate/:portfolioSlug',
  '/certificate/:id',
].filter((path) => path);

export const transparentRoutes = [
  '/fb',
  '/crypto',
  '/groups/:slug',
  '/funds/:id',
  '/join/:referralUsername',
  '/how-it-works',
  '/impact',
  '/start/:token',
  '/newsroom',
  '/partners',
  '/careers/:link',
  '/careers',
  '/methodologies/:slug',
  '/methodologies',
  '/portfolios/:slug',
  '/gifts/receive/:giftId',
  '/gifts',
  '/teams/business', // TODO: Convert back to /teams when waitlist test is done
  '/teams/profile/:username',
  '/emails/unsubscribe',
  '/emails/confirmation',
  '/climate-camp',
  '/leaderboard',
  '/groups',
  '/mail',
  '/community-challenge/nov-2023',
  '/',
];

// Mobile and desktop have opposite defalts: not fixed on mobile, fixed on desktop.
export const mobileFixedRoutes = ['/'];
export const desktopUnfixedRoutes = [
  '/choose-plan',
  '/projects',
  '/about',
  '/our-approach',
  '/offsets',
  '/faq',
  '/updates/:slug',
  '/blog/:slug',
];

export const noBottomBorderOnDesktopRoutes = [
  '/choose-plan',
  '/calculator/:question',
  '/calculator',
  '/offsets',
  '/offset-anything',
  '/about',
  '/start/:token',
  '/join/:referralUsername',
  '/about-us',
  '/our-approach',
  '/projects/:slug',
  '/projects',
  '/updates/:slug',
  '/blog/:slug',
  '/pwyw',
  '/faq',
  '/login',
  '/teams/:id',
  '/',
  '/blog',
];

export const darkBottomBorderOnDesktopRoutes = ['/profile/:username', '/login'];

export const whiteRoutes = [
  '/dashboard/:anything/:subpage',
  '/dashboard/:anything',
  '/dashboard',
  '/settings/:slug',
  '/settings',
  '/start/:token',
  '/put-a-price-on-carbon',
  '/choose-plan',
  '/calculator/:question',
  '/calculator',
  '/faq',
  '/how-it-works',
  '/about',
  '/about-us',
  '/offsets',
  '/pwyw',
  '/portfolios/:slug',
  '/blog/interactive/climate-policy-timeline',
  '/emails/unsubscribe',
  '/flights',
  '/emails/confirmation',
  '/projects',
  '/updates/:slug',
  '/projects/:slug',
  '/quiz/questions',
  '/quiz/results',
  '/quiz',
  '/fundraisers/community-tree-planting/success',
  '/fundraisers/community-tree-planting/payment',
  '/fundraisers/community-tree-planting/account',
  '/fundraisers/community-tree-planting',
  '/daily',
  '/our-approach',
  '/intro/:slug',
  isMobile() && '/success',
  '/funds/:id',
  '/teams/business', // TODO: Convert back to /teams when waitlist test is done

  '/groups',
  '/upgrade',
  '/login',
  '/success',
  '/updates',
  '/blog',
  '/fundraisers/:id/success',
  '/fundraisers/:id',
  '/',
  '/actions/:anything',
  '/actions',
].filter((path) => path);

export const giftingBannerPathnames = [
  '/careers',
  '/impact',
  '/faq',
  '/about-us',
  '/our-approach',
  '/projects',
  '/flights',
  '/',
];
