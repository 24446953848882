export function formatNumber(number: number, maximumFractionDigits = 2) {
  return Intl.NumberFormat(undefined, {
    style: 'decimal',
    maximumFractionDigits,
    minimumFractionDigits: 0,
  })
    .format(number)
    .replace(/\D00(?=\D*$)/, '');
  // The replace drops trailing decimal places (i.e. 3.00 -> 3)
  // Since we don't use this function to format currencies we don't want to show trailing zeroes
}

export function shortenNumber(number: number) {
  if (number < 1000) {
    return Math.round(number);
  }

  if (number < 1000000) {
    return `${(number / 1000).toFixed(1)}K`;
  }

  return `${(number / 1000000).toFixed(1)}M`;
}

export function formatPercentage(
  percentage: number,
  maximumFractionDigits = 2
) {
  return Intl.NumberFormat(undefined, {
    style: 'percent',
    maximumFractionDigits,
    minimumFractionDigits: 0,
  }).format(percentage);
}

export interface CurrencyOptions {
  amountCurrency: string;
  displayCurrency: string;
  maximumFractionDigits?: number;
  fromLowestDenominator?: boolean;
  abbreviate?: boolean;
}

// TODO: Remove this version of timeSince — seeing in duplicated in lib/time.
// taken directly from
// https://stackoverflow.com/questions/3177836/how-to-format-time-since-xxx-e-g-4-minutes-ago-similar-to-stack-exchange-site
export function timeSince(date: Date) {
  const seconds = Math.floor((Date.now() - Number(date)) / 1000);

  let interval = Math.floor(seconds / 31536000);

  if (interval > 1) {
    return `${interval} years`;
  }

  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return `${interval} months`;
  }

  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return `${interval} days`;
  }

  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return `${interval} hours`;
  }

  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return `${interval} minutes`;
  }

  return `${Math.floor(seconds)} seconds`;
}

export function formatOrdinalNumber(i: number) {
  if (i == null) {
    return null;
  }

  const j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return `${i}st`;
  }
  if (j === 2 && k !== 12) {
    return `${i}nd`;
  }
  if (j === 3 && k !== 13) {
    return `${i}rd`;
  }
  return `${i}th`;
}

export function formatDifference({
  numerator,
  denominator,
  renderMore,
  renderLess,
  renderSame,
}: {
  numerator: number;
  denominator: number;
  // eslint-disable-next-line
  renderMore: (difference: number) => string;
  // eslint-disable-next-line
  renderLess: (difference: number) => string;
  // eslint-disable-next-line
  renderSame: (difference: number) => string;
}) {
  const differenceBetweenNumberatorAndDenominator = numerator / denominator - 1;
  const differenceAsPositivePercentage = formatPercentage(
    Math.abs(differenceBetweenNumberatorAndDenominator),
    0
  );

  const percentageWithoutSpaces = differenceAsPositivePercentage.replace(
    /\W/g,
    ''
  );

  if (percentageWithoutSpaces === '0') {
    return renderSame(differenceBetweenNumberatorAndDenominator);
  }

  if (differenceBetweenNumberatorAndDenominator > 0) {
    return renderMore(differenceBetweenNumberatorAndDenominator);
  } else if (differenceBetweenNumberatorAndDenominator < 0) {
    return renderLess(differenceBetweenNumberatorAndDenominator);
  }
}

// @ts-expect-error - this should probably just never be used
export function extractTextFromReactElement(el) {
  if (el.props && el.props.children) {
    if (typeof el.props.children === 'string') {
      return el.props.children;
    }

    if (Array.isArray(el.props.children)) {
      return el.props.children
        .filter((child: any) => child)
        .map((child: any) => extractTextFromReactElement(child))
        .join('');
    }
    return extractTextFromReactElement(el.props.children);
  }

  return el.props ? el.props.children : '';
}

export const capitalizeFirstCharacter = (word: string) => {
  if (!word) return '';
  return word[0].toUpperCase() + word.slice(1).toLowerCase();
};

export const capitalizeWords = (text: string) => {
  if (!text) return '';
  return text.replace(/\w+/g, capitalizeFirstCharacter);
};

export const lowercaseFirstCharacter = (word: string) => {
  if (!word) return '';
  return word[0].toLowerCase() + word.slice(1);
};
