import { Trees } from '@wren/shared';
import * as R from 'ramda';
import { staticImageUrl } from '../util/images';

export const arrayOfCalculatorQuestionKeys = [
  'country',
  'zipcode',
  'calculationType',
  'transportNumberOfCars',
  'transportDomesticFlightsPerYear',
  'transportInternationalFlightsPerYear',
  'dietPreference',
  'dietRedMeatPerWeek',
  'dietOtherProteinsPerWeek',
  'dietDairyPerWeek',
  'homeSquareFeet',
  'pets',
  'transportMethod',
  'transportBusMilesPerWeek',
  'transportTransitRailMilesPerWeek',
  'transportInterCityRailMilesPerYear',
  'goodsFurnitureAppliancesDollarsSpentPerMonth',
  'goodsClothesDollarsSpentPerMonth',
  'goodsOtherDollarsSpentPerMonth',
  'servicesDollarsSpentPerMonth',
  'electricityKwhPerMonth',
  'electricityPercentRenewable',
  'naturalGasBurnedPerMonth',
  'finish',
];

export const dietQuestionKeyPairs = [
  {
    dietPreference: 'Vegan',
    relatedKeys: [
      'dietRedMeatPerWeek',
      'dietOtherProteinsPerWeek',
      'dietDairyPerWeek',
    ],
  },
  {
    dietPreference: 'Vegetarian',
    relatedKeys: ['dietRedMeatPerWeek', 'dietOtherProteinsPerWeek'],
  },
  {
    dietPreference: 'Neither',
    relatedKeys: [],
  },
];

export const transportMethodQuestionKeyPairs = [
  { transportMethod: 'bus', relatedKeys: ['transportBusMilesPerWeek'] },
  {
    transportMethod: 'transitRail',
    relatedKeys: ['transportTransitRailMilesPerWeek'],
  },
  {
    transportMethod: 'interCityRail',
    relatedKeys: ['transportInterCityRailMilesPerYear'],
  },
];

export function getTransportKeysToRemove(userTransportMethod) {
  // An unanswered question is null, but an empty string means 'none'
  if (userTransportMethod === null) return [];
  const keys = [];
  for (const {
    transportMethod,
    relatedKeys,
  } of transportMethodQuestionKeyPairs) {
    if (!userTransportMethod?.includes(transportMethod)) {
      keys.push(...relatedKeys);
    }
  }

  return keys;
}

export function getCarKeysToRemove(userAnswers) {
  const { transportCarIsElectric } = userAnswers;
  if (R.isEmpty(transportCarIsElectric) || R.isNil(transportCarIsElectric))
    return [];
  return transportCarIsElectric === 1
    ? ['transportMilesDrivenPerWeek', 'transportCarMpg']
    : [];
}

export function getDietKeysToRemove(userDietPreference) {
  if (R.isEmpty(userDietPreference) || R.isNil(userDietPreference)) return [];
  const removeQuestionKeysForDietPreference = (dietQuestionKeyPair) => {
    const { dietPreference, relatedKeys } = dietQuestionKeyPair;
    if (R.equals(dietPreference, userDietPreference)) return relatedKeys;
    return null;
  };
  return userDietPreference
    ? R.pipe(
        R.map((x) => removeQuestionKeysForDietPreference(x)),
        R.reject(R.isNil),
        R.flatten()
      )(dietQuestionKeyPairs)
    : [];
}

// selector
export function getCalculatorKeysForUser(state) {
  const isTeamMember = state.user?.team;
  const { userAnswers } = state.calculator;

  const transportKeysToRemove = getTransportKeysToRemove(
    userAnswers.transportMethod
  );
  const electricCarKeysToRemove = getCarKeysToRemove(userAnswers);
  const dietKeysToRemove = getDietKeysToRemove(userAnswers.dietPreference);

  const otherKeysToRemove = [];

  if (userAnswers.country !== 'United States') {
    otherKeysToRemove.push('zipcode');
  }

  if (isTeamMember) {
    otherKeysToRemove.push('calculationType');
  }

  const filteredKeys = R.pipe(
    R.without(transportKeysToRemove),
    R.without(electricCarKeysToRemove),
    R.without(dietKeysToRemove),
    R.without(otherKeysToRemove)
  )(arrayOfCalculatorQuestionKeys);

  if (userAnswers?.cars?.length > 0) {
    const indexOfNumberOfCars = filteredKeys.indexOf('transportNumberOfCars');

    if (indexOfNumberOfCars > -1) {
      filteredKeys.splice(
        indexOfNumberOfCars + 1,
        0,
        ...userAnswers.cars
          .sort((a, b) => a.id - b.id)
          .map((car) => `car${car.id}`)
      );
    }
  }

  return filteredKeys;
}

export function getCalculatorForUser(state) {
  const fullCalculator = R.pick(
    getCalculatorKeysForUser(state),
    state.calculator.userAnswers
  );
  return fullCalculator;
}

export function getIndexOfFirstUnansweredQuestion(state) {
  const calculatorKeysForUser = getCalculatorKeysForUser(state);

  if (!state.calculator.country) {
    return 0;
  }

  const indexOfFirstUnansweredQuestion = calculatorKeysForUser.findIndex(
    (question) => {
      return (
        question !== 'finish' &&
        question !== 'transportMethod' &&
        !question.startsWith('car') &&
        state.calculator.userAnswers[question] == null
      );
    }
  );

  // put user on country question if they have answered every question
  return indexOfFirstUnansweredQuestion !== -1
    ? indexOfFirstUnansweredQuestion
    : 0;
}

export function getCalculatorProgress(state) {
  const fullCalculator = getCalculatorForUser(state);
  const numberOfAnsweredQuestions = R.pipe(
    R.reject((answer) => R.isNil(answer)),
    R.values(),
    R.length()
  )(fullCalculator);
  return numberOfAnsweredQuestions / R.values(fullCalculator).length;
}

export const icons = {
  cloudCompostBin: staticImageUrl('calculator-icons/cloud_compost.png'),
  cloudShoppingBasket: staticImageUrl('calculator-icons/cloud_shop.png'),
  cloudBurger: staticImageUrl('calculator-icons/cloud_burger.png'),
  cloudNewCar: staticImageUrl('calculator-icons/cloud_new_car.png'),
  cloudGreenInvestment: staticImageUrl(
    'calculator-icons/cloud_green_investments.png'
  ),
  cloudPiggyBank: staticImageUrl('calculator-icons/cloud_piggy_bank.png'),
  cloudHardHat: staticImageUrl('calculator-icons/cloud_hard_hat.png'),
  cloudHotThermometer: staticImageUrl('calculator-icons/cloud_hot.png'),
  cloudColdThermometer: staticImageUrl('calculator-icons/cloud_cold.png'),
  cloudOutlet: staticImageUrl('calculator-icons/cloud_electrification.png'),
  cloudInductionStove: staticImageUrl(
    'calculator-icons/cloud_induction_stove.png'
  ),
  cloudBullhorn: staticImageUrl('calculator-icons/cloud_bullhorn.png'),
  cloudVeggies: staticImageUrl('calculator-icons/cloud_veggies.png'),
  cloudClothes: staticImageUrl('calculator-icons/cloud_clothes.png'),
  cloudEnergy: staticImageUrl('calculator-icons/cloud_battery.png'),
  cloudSupplies: staticImageUrl('calculator-icons/cloud_supplies.png'),
  cloudBus: staticImageUrl('calculator-icons/cloud_bus.png'),
  cloudCar: staticImageUrl('calculator-icons/cloud_car.png'),
  cloudMpg: staticImageUrl('calculator-icons/cloud_mpg.png'),
  cloudHouse: staticImageUrl('calculator-icons/cloud_house.png'),
  cloudRenewables: staticImageUrl('calculator-icons/cloud_renewables.png'),
  cloudInterCity: staticImageUrl('calculator-icons/cloud_inter-city.png'),
  cloudMeat: staticImageUrl('calculator-icons/cloud_meat.png'),
  cloudChicken: staticImageUrl('calculator-icons/cloud_chicken.png'),
  cloudDairy: staticImageUrl('calculator-icons/cloud_dairy.png'),
  cloudPlane: staticImageUrl('calculator-icons/cloud_plane.png'),
  cloudEarth: staticImageUrl('calculator-icons/cloud_earth.png'),
  cloudRail: staticImageUrl('calculator-icons/cloud_rail.png'),
  cloudServices: staticImageUrl('calculator-icons/cloud_services.png'),
  cloudFurniture: staticImageUrl('calculator-icons/cloud_couch.png'),
  cloudNaturalGas: staticImageUrl('calculator-icons/cloud_natural_gas.png'),
  cloudJumboJet: staticImageUrl('calculator-icons/cloud_plane_jumbo.png'),
  cloudFinishFlag: staticImageUrl('calculator-icons/cloud_finish_flag.png'),
  cloudHourglass: staticImageUrl('calculator-icons/cloud_hourglass.png'),
  cloudCoins: staticImageUrl('calculator-icons/cloud_coins.png'),
  cloudZipcode: staticImageUrl('calculator-icons/cloud_mailbox.png'),
  cloudFamily: staticImageUrl('calculator-icons/cloud_family.png'),
  cloudAdult: staticImageUrl('calculator-icons/cloud_adult.png'),
  cloudChild: staticImageUrl('calculator-icons/cloud_child.png'),
  cloudPet: staticImageUrl('calculator-icons/cloud_pet.png'),
  cloudSignpost: staticImageUrl('calculator-icons/cloud_signpost.png'),
  cloudClimber: staticImageUrl('calculator-icons/cloud_climber.png'),
  bullhorn: staticImageUrl('calculator-icons/bullhorn.png'),
  champagne: staticImageUrl('calculator-icons/champagne.png'),
  cake: staticImageUrl('calculator-icons/cake.png'),
  gift: staticImageUrl('gift-transparent.png'),
  veggies: staticImageUrl('calculator-icons/veggies.png'),
  renewables: staticImageUrl('calculator-icons/renewables.png'),
  plane: staticImageUrl('calculator-icons/plane.png'),
  car: staticImageUrl('calculator-icons/car.png'),
  services: staticImageUrl('calculator-icons/services.png'),
  naturalGas: staticImageUrl('calculator-icons/natural_gas.png'),
  meat: staticImageUrl('calculator-icons/meat.png'),
  clothes: staticImageUrl('calculator-icons/clothes.png'),
  house: staticImageUrl('calculator-icons/house.png'),
  energy: staticImageUrl('calculator-icons/energy.png'),
  cyclist: staticImageUrl('calculator-icons/cyclist.png'),
  hybrid: staticImageUrl('calculator-icons/electric_car.png'),
  electricCar: staticImageUrl('calculator-icons/electric_car.png'),
  thrifty: staticImageUrl('calculator-icons/thrifty.png'),
  noCar: staticImageUrl('calculator-icons/no_car.png'),
  noFly: staticImageUrl('calculator-icons/no_fly.png'),
  milk: staticImageUrl('calculator-icons/milk.png'),
  bus: staticImageUrl('calculator-icons/bus.png'),
};

// NOTE: this is likely wrong, and we aren't using it
export function translateTonsToTreesPlanted(tons) {
  if (!tons) return 0;
  return Number((tons / Trees.TONS_CO2_E_PER_TREE).toFixed(0)); // 21.7724kg of pine tree uptake over a year
}

export function translateTreesPlantedToTons(trees) {
  if (!trees) return 0;
  return Trees.TONS_CO2_E_PER_TREE * trees; // 21.7724kg of pine tree uptake over a year
}

export function translateTonsToPercentHumpbackWhaleWeight(tons) {
  if (!tons) return 0;
  return Number((tons / 36.287).toFixed(2)) * 100; // a whale weighs approx 36 tons
}

export function translateTonsToMilesDriven(tons) {
  if (!tons) return 0;
  return Number((tons / 0.0004039545455).toFixed(2));
}

export function translateTonsToCowsBurpingPerYear(tons) {
  if (!tons) return 0;
  return Number((tons / 4.5625).toFixed(2));
}

export function translateTonsToFlightsFromLAToCDG(tons) {
  if (!tons) return 0;
  return Number((tons / 2.06).toFixed(2));
}

export function translateTonsToBurgersEaten(tons) {
  if (!tons) return 0;
  return Number((tons / 0.00181437).toFixed(0));
}

export function translateTonsToSquareFeetOfSeaIceLoss(tons) {
  if (!tons) return 0;
  return Number((tons * 32.3).toFixed(0));
}

export function translateTonsToTreesCutPerYear(tons) {
  if (!tons) return 0;
  return 0.833 * tons;
}
