/** @jsxImportSource @emotion/react */

import {
  documentToReactComponents,
  RenderNode,
} from '@contentful/rich-text-react-renderer';
import { colors, spacing } from '../../style/theme';

import { BLOCKS, Document, INLINES } from '@contentful/rich-text-types';
import { HTMLAttributes, ReactElement } from 'react';
import { articleStyles, callout } from '../../style/article';
import HorizontalSeparator from '../UI/HorizontalSeparator';
import {
  isTypeButton,
  isTypeCalloutBox,
  isTypeDocumentLink,
  isTypeFaq,
  isTypeMessageWrenBox,
  isTypeVimeoVideo,
  isTypeWorldMap,
  isTypeYouTubeVideo,
} from '@wren/shared';
import { NavButton } from '../buttons/NavButton';
import DownloadableDocumentLink from '../projects/DownloadableDocumentLink';
import { Expandable } from '../UI/Expandable';
import { AssetFields } from 'contentful';
import { MessageWrenBox } from '../UI/MessageWrenBox';
import { GoogleMaps } from '../GoogleMaps/GoogleMaps';

type Props = HTMLAttributes<HTMLDivElement> & {
  document: Document;
  renderNode?: RenderNode;
  withArticleStyle?: boolean;
};

const articleElementTypes: Record<string, string> = {
  blockquote: 'blockquote',
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  li: 'li',
  ol: 'ol',
  p: 'p',
  table: 'table',
  td: 'td',
  th: 'th',
  tr: 'tr',
  ul: 'ul',
};

/***
 * We have a broad set of rules to be applied to blog articles: `articleStyles`,
 * but we want to avoid splitting article parts too much (affects selectors like 'p:last-of-type')
 * and we also don't want to apply any of those rules to embedded entities, because they affect common tags.
 * So, we wrap consecutive article elements into larger divs.
 ***/
function wrapArticleBlocks(body: ReactElement[]) {
  let articleChunk: ReactElement[] = [];
  const output: ReactElement[] = [];
  for (let index = 0; index < body.length; index++) {
    const node = body[index];
    if (
      node &&
      typeof node.type === 'string' &&
      articleElementTypes[node.type]
    ) {
      articleChunk.push(node);
      if (index === body.length - 1) {
        // end of body
        output.push(
          <div css={articleStyles} key={`article-chunk-${index}`}>
            {articleChunk}
          </div>
        );
      }
    } else {
      if (articleChunk.length > 0) {
        output.push(
          <div css={articleStyles} key={`article-chunk-${index}`}>
            {articleChunk}
          </div>
        );
        articleChunk = [];
      }
      output.push(node);
    }
  }
  return output;
}

function isExternal(href: string) {
  return /^http/.test(href) && !/^https?:\/\/([\w\d]+\.)?wren\.co/.test(href);
}

function mergeRenderNodes(a: RenderNode, b?: RenderNode): RenderNode {
  if (!b) return a;
  const merged: RenderNode = { ...b };
  for (const key in a) {
    if (b[key]) {
      merged[key] = (node, children) => {
        const element = b[key](node, children);
        if (element === undefined) {
          return a[key](node, children);
        }
        return element;
      };
    } else {
      merged[key] = a[key];
    }
  }
  return merged;
}

export default function RichText({
  withArticleStyle = true,
  document,
  renderNode,
  ...otherProps
}: Props) {
  const body = documentToReactComponents(document, {
    renderNode: mergeRenderNodes(
      {
        [INLINES.HYPERLINK]: ({ data }, children) => {
          return (
            <a
              href={data.uri}
              target={isExternal(data.uri) ? '_blank' : '_self'}
            >
              {children}
            </a>
          );
        },
        [BLOCKS.HEADING_2]: (node, children) => {
          const text = node.content
            .map(
              (content) =>
                content.nodeType === 'text' &&
                content.value
                  .replace(/\s+/g, '-')
                  .replace(/[^a-zA-Z0-9-]/g, '')
                  .toLowerCase()
            )
            .join('-');

          return <h2 id={text}>{children}</h2>;
        },
        [BLOCKS.HR]: () => <HorizontalSeparator />,
        [BLOCKS.EMBEDDED_ASSET]: (node) => {
          const fields = node.data.target.fields as AssetFields;
          return (
            <div
              css={{
                margin: `${spacing.default}px 0`,
                maxWidth: '100%',
              }}
            >
              <img
                src={`${fields.file?.url}`}
                css={{
                  maxWidth: '100%',
                }}
              />
              {fields.description && (
                <div
                  css={{
                    borderBottom: fields.description
                      ? `2px solid ${colors.borderColor}`
                      : '',
                    color: colors.textSecondary,
                    padding: spacing.small,
                  }}
                >
                  {fields.description}
                </div>
              )}
            </div>
          );
        },
        [BLOCKS.EMBEDDED_ENTRY]: (node) => {
          const { target } = node.data;
          if (isTypeButton<'WITHOUT_UNRESOLVABLE_LINKS', 'en-US'>(target)) {
            const { href, text } = target.fields;
            return (
              <div
                css={{
                  padding: spacing.medium,
                  textAlign: 'center',
                  width: '100%',
                }}
              >
                <NavButton
                  buttonType="filled"
                  color={colors.wrenOrange}
                  href={href}
                >
                  {text}
                </NavButton>
              </div>
            );
          }

          if (isTypeWorldMap<'WITHOUT_UNRESOLVABLE_LINKS', 'en-US'>(target)) {
            const project = target.fields.projectLocations?.[0];
            const location = project?.fields.location;
            return <GoogleMaps lat={location?.lat} long={location?.lon} />;
          }

          if (
            isTypeDocumentLink<'WITHOUT_UNRESOLVABLE_LINKS', 'en-US'>(target)
          ) {
            return <DownloadableDocumentLink {...target.fields} />;
          }

          if (isTypeFaq<'WITHOUT_UNRESOLVABLE_LINKS', 'en-US'>(target)) {
            return (
              <Expandable
                css={{ marginBottom: spacing.default }}
                heading={target.fields.heading}
              >
                <RichText
                  withArticleStyle={withArticleStyle}
                  document={target.fields.content}
                />
              </Expandable>
            );
          }

          if (
            isTypeMessageWrenBox<'WITHOUT_UNRESOLVABLE_LINKS', 'en-US'>(target)
          ) {
            return (
              <MessageWrenBox
                css={{
                  margin: `${spacing.xLarge}px auto ${spacing.default}px`,
                }}
                {...target.fields}
              />
            );
          }

          if (isTypeCalloutBox<'WITHOUT_UNRESOLVABLE_LINKS', 'en-US'>(target)) {
            return (
              <div
                css={[
                  callout,
                  { p: { lineHeight: 1.6 }, h2: { marginTop: 0 } },
                ]}
              >
                <RichText
                  document={target.fields.content}
                  withArticleStyle={false}
                />
              </div>
            );
          }

          if (
            isTypeYouTubeVideo<'WITHOUT_UNRESOLVABLE_LINKS', 'en-US'>(target)
          ) {
            return (
              <iframe
                css={{
                  border: 0,
                  margin: `${spacing.default}px auto ${spacing.default}px`,
                }}
                width="100%"
                height="400"
                src={`https://www.youtube.com/embed/${target.fields.pathAndQuery}`}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            );
          }

          if (isTypeVimeoVideo<'WITHOUT_UNRESOLVABLE_LINKS', 'en-US'>(target)) {
            return (
              <iframe
                css={{
                  border: 0,
                  margin: `${spacing.medium}px auto ${spacing.default}px`,
                }}
                src={`https://player.vimeo.com/video/${target.fields.pathAndQuery}`}
                width="100%"
                height="400"
                title="Vimeo video player"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
              ></iframe>
            );
          }
        },
      },
      renderNode
    ),
  }) as ReactElement[];

  return (
    <div {...otherProps}>
      {withArticleStyle ? wrapArticleBlocks(body) : body}
    </div>
  );
}
