/** @jsxImportSource @emotion/react */

import Button from '../components/buttons/Button';
import { scale } from '../util/scale';
import * as misc from '../style/misc';
import MessageCard from '../components/UI/MessageCard';
import { connect } from 'react-redux';
import { Session } from '@wren/shared';
import { usePostHog } from 'posthog-js/react/';
const { clearSession } = Session;

const messageCard = scale({
  width: ['100%', '90%', '90%', '80%'],
  marginTop: ['120px', '120px', '130px', '140px'],
  margin: 'auto',
  textAlign: 'center',
});

function CancellationMessage(props) {
  const posthog = usePostHog();

  if (props.user.hasPassword) {
    clearSession();
    posthog.reset();
  }

  return (
    <div css={messageCard}>
      <MessageCard
        type="welcome"
        header="We're sad to see you go!"
        description="Your subscription has been cancelled, and along with it, all of your information has been removed."
        noShare
      />
      <div css={[misc.marginAuto, misc.maxWidth(200)]}>
        <Button
          text="Done &rarr;"
          size="large"
          onClickFn={() => (window.location.href = '/logout')}
        />
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, null)(CancellationMessage);
