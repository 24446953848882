// filepath: /Users/ben/code/wren/next/svgs/logoX.tsx
import React from 'react';

interface LogoXProps {
  width?: number;
  height?: number;
}

const LogoX: React.FC<LogoXProps> = ({ width = 18, height = 18 }) => {
  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 300 271"
    >
      <path d="m236 0h46l-101 115 118 156h-92.6l-72.5-94.8-83 94.8h-46l107-123-113-148h94.9l65.5 86.6zm-16.1 244h25.5l-165-218h-27.4z" />
    </svg>
  );
};

export default LogoX;
