/** @jsxImportSource @emotion/react */

import { Link } from '../UI/Link';
import { scale } from '../../util/scale';
import { staticImageUrl } from '../../util/images';
import FooterCTA from '../frontfacing/FooterCTA';
import { noStyleLink } from '../../style/text';
import { DEFAULT_PORTFOLIO_NAME } from '../../config/constants';
import { colors, spacing } from '../../style/theme';
import NewTag from '../UI/NewTag';
import LogoFacebook from '../svgs/logoFacebook';
import LogoInstagram from '../svgs/logoInstagram';
import LogoLinkedin from '../svgs/logoLinkedin';
import LogoX from '../svgs/logoX';
import LogoYoutube from '../svgs/logoYoutube';

const footerHeader = scale({
  textAlign: 'left',
  fontWeight: 600,
  fontSize: '16px',
  color: colors.textPrimary,
});

const footerLink = scale({
  textDecoration: 'none',
  fontWeight: '400',
  borderRadius: '3px',
  padding: '3px 7px',
  marginLeft: '-7px',
  textAlign: 'left',
  color: colors.textPrimary,
  fontSize: '15px',
  marginTop: '10px',
  cursor: 'pointer',
  lineHeight: 1.42857143, // from css in js. necessary for correct spacing of links
  '&:hover': {
    backgroundColor: 'rgba(51, 51, 51, 0.1)',
  },
  display: 'block',
  width: 'fit-content',
});

const footerHr = scale({
  width: '100%',
  marginTop: '16px',
});

const footer = scale({
  width: '100%',
  paddingBottom: spacing.xxLarge,
  backgroundColor: 'white',
  hr: {
    marginBottom: '16px',
    opacity: 0.2,
  },
  borderTop: `1px solid ${colors.borderColor}`,
});

const copyrightText = scale({
  fontWeight: 600,
  textAlign: 'left',
});

const primaryContainer = scale({
  width: '90%',
  margin: 'auto',
  maxWidth: 1200,
  position: 'relative',
});

const container = scale({
  width: '100%',
});

const footerColumns = scale({
  display: 'flex',
  flexWrap: ['wrap', 'wrap', 'nowrap', 'nowrap'],
  flexDirection: ['column-reverse', 'column-reverse', 'unset', 'unset'],
});

const footerCol = scale({
  width: ['100%', '100%', '34%', '34%'],
  marginBottom: '50px',
  textAlign: 'left',
});

const footerColMedium = scale({
  width: ['100%', '100%', '32%', '32%'],
  marginBottom: '50px',
  textAlign: 'left',
});

const footerColSmall = scale({
  width: ['100%', '100%', '20%', '20%'],
  marginBottom: '50px',
  textAlign: 'left',
});

const lightText = scale({
  opacity: 0.85,
  fontSize: '14px',
  marginTop: '5px',
  color: colors.textPrimary,
  marginBottom: '5px',
  textDecoration: 'none',
  fontWeight: 400,
});

const oneLiner = scale({
  width: '100%',
  textAlign: 'left',
  marginLeft: [0, 0, '10px'],
});

const oneLinerContent = scale({
  paddingRight: '15px',
  maxWidth: '200px',
  h4: {
    marginBottom: '12px',
  },
});

export default function Footer(props) {
  const { ctaClickFn, className, referralCta } = props;
  return (
    <div css={footer} className={className}>
      {referralCta && ctaClickFn && (
        <FooterCTA ctaClickFn={ctaClickFn} referralCta={referralCta} />
      )}
      <div css={primaryContainer}>
        <div css={container}>
          <div style={{ paddingTop: '50px' }}>
            <div css={footerColumns}>
              <div css={footerColMedium}>
                <div style={{ display: 'flex' }}>
                  <div css={oneLiner}>
                    <div css={oneLinerContent}>
                      <h4 css={footerHeader}>Wren Climate, PBC</h4>
                      <p css={lightText}>
                        Take real, effective climate action with Wren’s
                        all-in-one climate platform.
                      </p>
                      <div
                        css={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          gap: spacing.small,
                          marginTop: spacing.medium,
                          a: {
                            opacity: 0.6,
                            transition: 'opacity 0.2s',
                            '&:hover': {
                              opacity: 1,
                            },
                          },
                        }}
                      >
                        <a
                          css={noStyleLink}
                          href="https://www.youtube.com/@project_wren"
                          target="_blank"
                        >
                          <LogoYoutube width={18} height={18} />
                        </a>
                        <a
                          css={noStyleLink}
                          href="https://twitter.com/project_wren"
                          target="_blank"
                        >
                          <LogoX width={18} height={18} />
                        </a>
                        <a
                          css={noStyleLink}
                          href="https://www.instagram.com/project_wren/"
                          target="_blank"
                        >
                          <LogoInstagram width={18} height={18} />
                        </a>
                        <a
                          css={noStyleLink}
                          href="https://www.linkedin.com/company/project-wren/"
                          target="_blank"
                        >
                          <LogoLinkedin width={18} height={18} />
                        </a>
                        <a
                          css={noStyleLink}
                          href="https://www.facebook.com/projectwren"
                          target="_blank"
                        >
                          <LogoFacebook width={18} height={18} />
                        </a>
                      </div>
                      <br />
                      <br />
                      {/* {canUserChangeCurrency(user) && <CurrencyMenu />} */}
                    </div>
                  </div>
                </div>
              </div>
              <div css={footerColSmall}>
                <h4 css={footerHeader}>Products</h4>
                <Link css={footerLink} to="/continue">
                  Subscription
                </Link>
                <Link css={footerLink} to="/actions">
                  Reduction guides
                </Link>
                <Link css={footerLink} to="/calculator">
                  Carbon calculator
                </Link>
                <Link css={footerLink} to="/flights">
                  Offset flights
                </Link>
                <Link css={footerLink} to="/offset-anything">
                  Offset anything
                </Link>
                <Link css={footerLink} to="/gifts">
                  Purchase gifts
                </Link>
                <Link css={footerLink} to="/wren-api">
                  Wren API
                </Link>
                <Link css={footerLink} to="/teams/business">
                  Wren for Business
                </Link>
              </div>
              <div css={footerColSmall}>
                <h4 css={footerHeader}>Resources</h4>
                {/* <Link css={footerLink} to="/careers">
                  Careers <NotificationDot />
                </Link> */}
                <a css={footerLink} href="mailto:support@wren.co">
                  Support
                </a>
                <a css={footerLink} href="mailto:team@wren.co">
                  Contact us
                </a>
                {/* <a
                  css={footerLink}
                  href=" https://www.shareasale.com/shareasale.cfm?merchantID=105790"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Affiliate program
                </a> */}
                <Link css={footerLink} to="/projects">
                  Our projects
                </Link>
                <Link css={footerLink} to="/our-approach">
                  Our approach
                </Link>
                <Link css={footerLink} to="/about">
                  About us
                </Link>
                <Link css={footerLink} to="/updates">
                  Project updates
                </Link>
                <Link css={footerLink} to="/faq">
                  FAQ
                </Link>
              </div>
              <div css={footerCol}>
                <h4 css={footerHeader} style={{ display: 'inline-block' }}>
                  Wren for...
                </h4>
                <Link css={footerLink} to="/continue">
                  Individuals & Families
                </Link>
                <Link css={footerLink} to="/business">
                  Business
                </Link>
                <Link css={footerLink} to="/collective">
                  Philanthropy <NewTag />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div
          css={scale({
            display: ['none', 'none', 'none', 'flex'],
            justifyContent: 'flex-end',
            width: '100%',
            marginBottom: spacing.large,
          })}
        >
          <span
            css={{
              display: 'flex',
              width: '70%',
              gap: 32,
            }}
          >
            <a
              target="_blank"
              href="https://directories.onepercentfortheplanet.org/profile/wren-climate-pbc"
            >
              <img
                alt="One Percent for the Planet logo"
                src={staticImageUrl('one-percent-for-the-planet.png', {
                  w: 300,
                })}
                css={{
                  maxHeight: 50,
                  width: 'auto',
                }}
              />
            </a>
            <a target="_blank" href="https://www.trustpilot.com/review/wren.co">
              <img
                alt="Trustpilot logo"
                src={staticImageUrl('trustpilot-widget.png', { w: 300 })}
                css={{
                  maxHeight: 50,
                  width: 'auto',
                }}
              />
            </a>
            <a
              target="_blank"
              href="https://www.treehugger.com/eco-tech-awards-2021-5185022"
            >
              <img
                alt="Treehugger Best of Green Tech 2021"
                src={staticImageUrl('wren-best-of-green-tech.png', { w: 500 })}
                css={{
                  maxHeight: 50,
                  width: 'auto',
                }}
              />
            </a>
            <a
              target="_blank"
              href="https://www.producthunt.com/stories/announcing-the-2019-golden-kitty-award-winners"
            >
              <img
                alt="Product Hunt Social Impact Product of the Year"
                src={staticImageUrl(
                  'wren-producthunt-social-impact-product-of-year.png',
                  { w: 500 }
                )}
                css={{
                  maxHeight: 50,
                  width: 'auto',
                }}
              />
            </a>
          </span>
        </div>
        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div
            css={scale({
              width: '20%',
              marginTop: -140,
              marginLeft: -28,
              display: ['none', 'none', 'none', 'block'],
            })}
          >
            <a target="_blank" href="/charter">
              <img
                alt="Cartoon Wren bird waving."
                src={staticImageUrl('/mascot/wren-bird.png', {
                  w: 800,
                })}
                css={{
                  maxHeight: 200,
                  width: 'auto',
                }}
              />
            </a>
          </div>
          <span
            css={scale({
              display: ['none', 'none', 'none', 'flex'],
              width: '70%',
              gap: 32,
            })}
          >
            <a href="/charter" css={[noStyleLink, copyrightText]}>
              Wren Climate, PBC © {new Date().getFullYear()}
            </a>
            <a
              css={noStyleLink}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.notion.so/projectwren/Wren-Privacy-Policy-f237e9d54e624877be18cc8362fe29cc"
            >
              Privacy Policy
            </a>
            <a
              css={noStyleLink}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.notion.so/projectwren/Website-Data-Collection-Policy-6eaaf11f4fa24869b6754771d9cfedee"
            >
              Data Collection
            </a>
          </span>
        </div>
      </div>
    </div>
  );
}
