// This is permissive so that app state can represent an uninitialized or deleted field with any non-numeric falsy value, and differentiate legitimate 0-length string values.
export type MaybeString = string | undefined | null | false;

// Returns an error string or null
type Validation = (value: MaybeString) => string | null;

// Uses a boolean function and an error string as a validation (which returns the error string when the function returns false)
export function it(
  test: (value: MaybeString) => boolean,
  error: string
): Validation {
  return (value: MaybeString) => (test(value) ? null : error);
}

export function getFirstValidationError(
  value: MaybeString,
  ...validations: Validation[]
) {
  for (const validate of validations) {
    const error = validate(value);
    if (error) {
      return error;
    }
  }
  return null;
}

// Validations is array-like rather than a mapped type because you can have more than one validation/error per key
export function getFirstFormValidationError<
  Key extends string | number | symbol
>(
  value: { [Property in Key]: MaybeString },
  ...validations: [Key, Validation][]
) {
  for (const [key, validate] of validations) {
    const error = validate(value[key]);
    if (error) {
      return error;
    }
  }
  return null;
}

/*
 * Roughly RFC 5321 compliant (canonical) email addresses
 * This is the "input validation" regex from https://stackoverflow.com/a/14075810
 * This should be at least as strict as RFC 5322 as well, to keep customer.io happy
 * https://docs.customer.io/journeys/manually-adding-or-updating-people/#email-address-validation
 */
const EMAIL_REGEX =
  /^([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|\[[\t -Z^-~]*])$/;

export function isValidEmail(email: MaybeString) {
  return !!email && EMAIL_REGEX.test(email);
}

// Grabbed the shorter Regex suggested in:
// https://stackoverflow.com/questions/161738/what-is-the-best-regular-expression-to-check-if-a-string-is-a-valid-url
const URL_REGEX =
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;

export function isValidURL(url: MaybeString) {
  return !!url && URL_REGEX.test(url);
}

export function isNotEmpty(field: MaybeString) {
  return !!field && field.trim() !== '';
}

export function isAtLeastEightCharacters(password: MaybeString) {
  return !!password && password.length >= 8;
}

const ALPHANUMERIC_REGEX = /^[a-zA-Z0-9]+$/;

export function isAlphanumeric(value: MaybeString) {
  return !!value && ALPHANUMERIC_REGEX.test(value);
}

export function doesNotMatch(prev: string) {
  return (value: MaybeString) => value !== prev;
}

export function getUsernameValidationError(
  username: MaybeString,
  oldUsername: string
) {
  return getFirstValidationError(
    username,
    it(isNotEmpty, 'Username required'),
    it(isAlphanumeric, 'Invalid characters'),
    it(doesNotMatch(oldUsername), 'New username cannot match current username')
  );
}

export const isRequired = (error: string) => (value: MaybeString) =>
  isNotEmpty(value) ? null : error;

export function getPasswordValidationError(password: MaybeString) {
  return getFirstValidationError(
    password,
    it(isNotEmpty, 'Password is required'),
    it(isAtLeastEightCharacters, 'Password must be at least 8 characters')
  );
}

export function getEmailValidationError(email: MaybeString) {
  return getFirstValidationError(
    email,
    it(isNotEmpty, 'Email is required'),
    it(isValidEmail, 'Invalid email')
  );
}

export type LoginForm = { email: string; password: string };
export function getLoginValidationError(form: LoginForm) {
  return getFirstFormValidationError<keyof LoginForm>(
    form,
    ['email', getEmailValidationError],
    ['password', getPasswordValidationError]
  );
}

export type NewAccountForm = LoginForm & {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  twilioConsent: boolean;
};
