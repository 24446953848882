/** @jsxImportSource @emotion/react */

import { Helmet } from 'react-helmet';
import Footer from '../components/frontfacing/Footer';
import Container, { FullBleedSection } from '../components/UI/Container';
import Flex from '../components/UI/Flex';
import { Headings, Paragraph } from '../components/UI/TextStyles';
import { isMobile } from '../lib/platform';
import { staticImageUrl } from '../util/images';
import { scale } from '../util/scale';
import { SerifParagraph } from '../style/article';
import { colors, fonts, spacing } from '../style/theme';
import { HTMLProps } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { FounderFaces } from '../components/UI/FounderFaces';
import OrderedList from '../components/UI/OrderedList';

const CONTENT_WIDTH = 600;

const whiteBg = scale({
  backgroundColor: 'white',
  width: '100%',
  margin: 0,
  position: 'relative',
  overflow: 'hidden',
  fontFeatureSettings: "'ss03' on, 'ss01' on",
  overflowX: 'hidden',
  h1: {
    letterSpacing: 0,
  },
  h2: {
    fontFamily: 'InterDisplay, Inter, sans-serif',
    fontFeatureSettings: "'ss03' on, 'ss01' on",
  },
  li: {
    '&:before': {
      top: 8,
      fontWeight: 700,
      fontFamily: 'InterDisplay, Inter, sans-serif',
      fontFeatureSettings: "'ss03' on, 'ss01' on",
    },
  },
});

const dropcapParagraph = scale({
  '&:first-child:first-letter': {
    float: 'left',
    fontSize: 75,
    lineHeight: 0.8,
    paddingRight: spacing.small,
    paddingBottom: spacing.xxSmall,
  },
});

const reportingCallout = scale({
  backgroundColor: '#f6f9fc',
  border: '1px solid #ccc',
  padding: spacing.large,
  borderRadius: 6,
  marginTop: spacing.large,
  marginBottom: spacing.xLarge,
  li: {
    marginBottom: 16,
    fontFamily: fonts.Serif,
    fontSize: 20,
  },
  a: {
    // Change bottom underline opacity to 0.5
    textDecoration: 'none',
    borderBottom: '2px solid rgba(0,0,0,0.1)',
  },
});

const funnyLilQuotationThing = (
  <svg
    width="39"
    height="20"
    viewBox="0 0 39 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    css={{ position: 'relative', right: spacing.medium }}
  >
    <path
      d="M1.91699 1.16313C4.41443 2.0318 7.69749 1.50496 10.2919 1.50496C11.2991 1.50496 11.8903 1.41453 12.7037 1.18212C13.8987 0.840704 13.9868 2.6625 14.4889 3.29009C15.3929 4.42007 15.5454 7.07355 15.9512 8.49356C16.1957 9.34954 16.9494 10.4678 17.5654 11.1523C18.5178 12.2106 19.4412 12.7991 20.5469 13.659C23.137 15.6735 26.4471 16.3238 28.9978 18.1788C29.2623 18.3712 31.4188 19.0805 30.7829 18.4447C29.6674 17.3292 27.8367 16.5527 26.605 15.4442C25.1392 14.125 23.7943 11.2959 23.7943 9.36713C23.7943 7.94282 23.7943 6.51852 23.7943 5.09421C23.7943 3.99407 24.4205 2.82448 24.478 1.84679C24.5751 0.195361 29.5363 1.78598 30.631 1.84679C32.9815 1.97738 35.196 1.50496 37.4677 1.50496"
      stroke="#00836C"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
);

function Callout({
  color,
  textColor,
  children,
  quoteText,
}: {
  color: string;
  textColor: string;
  quoteText: string;
  children?: React.ReactNode;
}) {
  return (
    <aside
      css={{
        padding: '34px 46px',
        backgroundColor: color,
        color: textColor,
        textAlign: 'center',
        borderRadius: 6,
        marginTop: 34,
        marginBottom: 34,
      }}
    >
      <Headings.Medium
        font="serif"
        css={{
          b: {
            fontSize: '130%',
            fontWeight: '700 !important',
            lineHeight: 1.4,
          },
        }}
      >
        <b>{quoteText}</b>
      </Headings.Medium>
      {children}
    </aside>
  );
}

const bottomBorderDivider = scale({
  borderBottom: `1px solid ${colors.borderColor}`,
  paddingBottom: spacing.medium,
  marginBottom: spacing.medium,
});

function IllustrationSectionHeader({
  bigNumber,
  illustrationImageUrl,
  illustration,
  title,
  illustrationWidth,
  ...restOfProps
}: {
  illustrationImageUrl?: string;
  illustration?: React.ReactNode;
  illustrationWidth?: number;
  title: string;
  bigNumber?: string;
} & HTMLProps<HTMLDivElement>) {
  return (
    <div css={[{ textAlign: 'center' }, bottomBorderDivider]} {...restOfProps}>
      {bigNumber && (
        <Headings.Massive
          font="serif"
          css={{
            fontFamily: 'Blanco, serif !important',
            // fontVariantNumeric: 'tabular-nums',
            color: colors.wrenOrange,
            marginBottom: spacing.default,
          }}
        >
          {bigNumber}
        </Headings.Massive>
      )}
      {illustrationImageUrl ? (
        <img
          alt={title}
          src={illustrationImageUrl}
          css={{ width: illustrationWidth, marginBottom: spacing.default }}
        />
      ) : (
        illustration
      )}
      <Headings.Large font="sans">{title}</Headings.Large>
    </div>
  );
}

export default function UpdatedCharter() {
  const FullBleedOnMobile = isMobile() ? FullBleedSection : 'div';

  const headerImageName = 'charter-hero.jpeg';

  const [image, setImage] = useState(
    staticImageUrl(headerImageName, { blur: 200, q: 1, w: 50 })
  );

  useEffect(() => {
    setImage(staticImageUrl(headerImageName, { blur: 200, q: 1, w: 50 }));
  }, [headerImageName]);

  useEffect(() => {
    const removeBlur = () => {
      setImage(staticImageUrl(headerImageName));
    };
    removeBlur();
  }, [image]);

  return (
    <div css={whiteBg}>
      <Helmet>
        <title>Wren | Public Benefit Charter</title>
      </Helmet>
      <header
        css={scale({
          textAlign: 'center',
          padding: [
            '100px 16px 50px',
            '100px 16px 50px',
            '120px 50px 50px 50px',
            '120px 50px 50px 50px',
          ],
        })}
      >
        <Headings.Massive
          css={{
            marginBottom: 0,
            maxWidth: 500,
            fontSize: [
              '38px !important',
              '38px !important',
              '45px !important',
              '45px !important',
            ],
          }}
        >
          Wren is a Public <br />
          Benefit Corporation.
        </Headings.Massive>
        <Flex
          css={{ margin: `${spacing.default}px auto 0`, width: 'fit-content' }}
          align="center"
        >
          <FounderFaces />
          <div css={{ marginLeft: spacing.default, textAlign: 'left' }}>
            <Paragraph weight={600}>Wren’s Founders</Paragraph>
            <Paragraph>Ratified in 2020</Paragraph>
          </div>
        </Flex>
      </header>
      <Container width={1000}>
        <FullBleedOnMobile>
          <img css={{ width: '100%', maxHeight: 522 }} alt="TODO" src={image} />
        </FullBleedOnMobile>
      </Container>
      {/* <Container width={CONTENT_WIDTH}>
        <div
          css={[
            bottomBorderDivider,
            {
              fontFamily: fonts.Serif,
              fontStyle: 'italic',
              color: colors.textSecondary,
              fontSize: '16px !important',
              marginTop: spacing.medium,
            },
          ]}
        >
          Special thanks to Erika Reinhardt, Eric Holthaus, Hank Green, and
          other climate and science experts for providing feedback on this post.
        </div>
      </Container> */}
      <Container
        width={CONTENT_WIDTH}
        css={{ marginBottom: spacing.medium, marginTop: spacing.large }}
      >
        {/* <TableOfContents
          sections={[
            {
              name: 'How carbon offsets can fail',
              anchor: '#how-fail',
            },
            {
              name: 'Why funding good offsets is necessary',
              anchor: '#why-necessary',
            },
            {
              name: 'How Wren is finding and funding good projects',
              anchor: '#how-we-find',
            },
            { name: 'Wren’s research process', anchor: '#process' },
            { name: 'What’s next?', anchor: '#whats-next' },
          ]}
        /> */}
      </Container>
      <Container width={CONTENT_WIDTH} css={{ marginBottom: spacing.xxLarge }}>
        <SerifParagraph css={dropcapParagraph}>
          <b>We started Wren</b> because as a planet, we aren't moving fast
          enough to end the climate crisis. Our mission is simple: Help reverse
          the climate crisis. As such, we measure Wren's success by how well we
          achieve our mission, rather than by the size of our profits.
        </SerifParagraph>
        <SerifParagraph>
          Wren is a U.S.{' '}
          <a href="https://projectwren.imgix.net/wren_climate_pbc.pdf">
            Public Benefit Corporation (PBC)
          </a>
          , which means we are legally bound to consider the impact of our
          decisions on society, along with our bottom line. This{' '}
          <b>legally-binding charter</b> is our commitment to our members,
          partners, and the planet. Not a word has changed since we first
          incorporated as a PBC in 2020.
        </SerifParagraph>
      </Container>
      <Container width={CONTENT_WIDTH} css={{ marginBottom: spacing.xxLarge }}>
        <IllustrationSectionHeader
          illustrationWidth={100}
          bigNumber="1."
          title="Wren’s mission is to play a significant role in reversing the climate crisis."
          id="how-fail"
        />
        <OrderedList listStyleType="upper-alpha">
          <li>
            <SerifParagraph>
              Wren will offer tools, services, and resources that help people
              take action to reverse the climate crisis.
            </SerifParagraph>
          </li>
          <li>
            <SerifParagraph>
              Wren will strive to help other organizations working on reversing
              climate change even if they could be considered competitors.
            </SerifParagraph>
          </li>
          <li>
            <SerifParagraph>
              Wren recognizes that climate change is a multifaceted global
              problem and will solicit and consider input from a global audience
              across ages, genders, ethnicities, abilities, backgrounds, and
              identities.
            </SerifParagraph>
          </li>
        </OrderedList>
        <div css={{ marginTop: spacing.xxLarge }} />
        <IllustrationSectionHeader
          illustrationWidth={100}
          bigNumber="2."
          title="Wren will be radically transparent."
          id="radical-transparency"
        />
        <OrderedList listStyleType="upper-alpha">
          <li>
            <SerifParagraph>
              Wren will strive to make its operations and plans transparent to
              anyone inside or outside the organization.
            </SerifParagraph>
          </li>
          <li>
            <SerifParagraph>
              Wren will publish ongoing financial and operating metrics showing
              our impact, efficiency, and effectiveness.
            </SerifParagraph>
          </li>
        </OrderedList>

        <div css={{ marginTop: spacing.xxLarge }} />

        <IllustrationSectionHeader
          illustrationWidth={100}
          bigNumber="3."
          title="Wren will rigorously evaluate its own effectiveness."
          id="evaluate-effectiveness"
        />
        <OrderedList listStyleType="upper-alpha">
          <li>
            <SerifParagraph>
              Wren will heavily vet the effectiveness and efficiency of any
              climate change solution it proposes to users.
            </SerifParagraph>
          </li>
          <li>
            <SerifParagraph>
              Wren will share its methodologies for evaluating climate change
              solutions publicly.
            </SerifParagraph>
          </li>
        </OrderedList>

        <div css={{ marginTop: spacing.xxLarge }} />

        <IllustrationSectionHeader
          illustrationWidth={100}
          bigNumber="4."
          title="Wren will be a role model for how all corporations should act."
          id="role-model"
        />
        <OrderedList listStyleType="upper-alpha">
          <li>
            <SerifParagraph>
              Wren will mitigate its environmental harm, and will always remove
              or offset at least twice the amount of carbon the organization and
              its employees are responsible for.
            </SerifParagraph>
          </li>
          <li>
            <SerifParagraph>
              Wren will take a stance on political issues that further its
              mission, but will not lobby or campaign purely for its own
              financial benefit.
            </SerifParagraph>
          </li>
          <li>
            <SerifParagraph>
              Wren will never sell user data to third parties. It will adamantly
              defend the privacy rights of its users and embrace new legislation
              that improves privacy.
            </SerifParagraph>
          </li>
          <li>
            <SerifParagraph>
              Wren will not use deceptive but legal tax management strategies to
              reduce its tax burden. It will be transparent in reporting the
              percentage of taxes it pays.
            </SerifParagraph>
          </li>
          <li>
            <SerifParagraph>
              Wren will report on team and leadership demographics, executive
              and CEO pay ratios, and programs and strategies employed to build
              a diverse, inclusive, and equitable organization.
            </SerifParagraph>
          </li>
        </OrderedList>
        <div css={reportingCallout}>
          <h1>Reporting</h1>
          <SerifParagraph>
            Wren will deliver an annual report outlining its continuous
            commitments to the charter, in addition to ongoing public
            documentation.
          </SerifParagraph>
          <ul>
            <li>
              <a
                target="_blank"
                href="https://www.notion.so/projectwren/f39023cc81034d2ca64d1fa184e685ad?v=80ee68408a4a4015a1d471485b860504"
                rel="noreferrer"
              >
                Public roadmap
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.notion.so/Wren-Receipt-Ledger-ce9131ad39ef42c3b52f7ea6943c8e18"
                rel="noreferrer"
              >
                Public receipts ledger
              </a>
            </li>
            <li>
              <a target="_blank" href="/impact">
                Impact live feed
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://projectwren.imgix.net/wren_climate_pbc.pdf"
                rel="noreferrer"
              >
                Public Benefit incorporation document
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.wren.co/blog/year-1-financial-report"
                rel="noreferrer"
              >
                Wren annual report (Y1)
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.wren.co/blog/wren-financial-report-year-two"
                rel="noreferrer"
              >
                Wren annual report (Y2)
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.wren.co/blog/wren-financial-report-year-three"
                rel="noreferrer"
              >
                Wren annual report (Y3)
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.wren.co/blog/wren-financial-report-year-four"
                rel="noreferrer"
              >
                Wren annual report (Y4)
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.wren.co/blog/wren-financial-report-year-five"
                rel="noreferrer"
              >
                Wren annual report (Y5)
              </a>
            </li>
          </ul>
        </div>
        <SerifParagraph>
          Special thanks to Yancey Strickler, our friend and mentor, for
          exposing us to what a 21st century company can and should look like.
        </SerifParagraph>
        <SerifParagraph>
          Thank you to Wren members Pratima, Alastair, Nate, Alex, Michael, and
          Taylor (who joined Wren as our first engineer!) for providing valuable
          feedback on the first draft of our charter.
        </SerifParagraph>

        <div css={{ marginTop: spacing.xxLarge }} />

        <SerifParagraph>
          Signed,
          <br />
          <br />
          Landon Brand
          <br /> Mimi Tran Zambetti <br />
          Benjamin Stanfield
        </SerifParagraph>
      </Container>
      <Footer hideCTA />
    </div>
  );
}
