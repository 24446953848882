/** @jsxImportSource @emotion/react */

import styled from '@emotion/styled';
import { usePostHog } from 'posthog-js/react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useCart from '../../hooks/useCart';
import { formatCurrency } from '../../lib/formatCurrency';
import { getLatestSubscription } from '../../lib/subscriptions';
import { State } from '../../store';
import { h3 } from '../../style/text';
import { staticImageUrl } from '../../util/images';
import { scale } from '../../util/scale';
import Button from '../buttons/Button';
import Dialog from '../layout/Dialog';
import Link from '../UI/Link';

const topAnnouncement = (color = 'var(--wren-green)') =>
  scale({
    textAlign: 'center',
    backgroundColor: color,
    color: 'white',
    position: 'relative',
    boxSizing: 'border-box',
    padding: '14px 8px',
  });

const announcementContent = scale({
  cursor: 'pointer',
  width: 'auto',
  maxWidth: 800,
  margin: '0 auto',
  padding: 8,
  p: {
    fontSize: [16, 16, 17],
  },
});

const whiteLink = scale({
  color: 'white',
  textDecoration: 'none',
  '&:hover': {
    color: 'white',
    textDecoration: 'underline',
  },
});

const Content = styled.div`
  max-width: 420px;
  text-align: center;
  padding: 28px 28px 42px;
`;

const Title = styled.h3(
  h3,
  `
  font-weight: 600;
  font-size: 26px;
  line-height: 105.84%;
  margin-bottom: 12px;
`
);

const Image = styled.img`
  width: 80px;
  margin-bottom: 12px;
`;

const Description = styled.p`
  font-size: 18px;
  line-height: 135%;
  margin-bottom: 32px;
`;

export default function SubscriptionChurnNotice() {
  const [show, setShow] = useState(true);
  const user = useSelector((state: State) => state.user);
  const { addSubscriptionCartItem } = useCart();
  const history = useHistory();
  const posthog = usePostHog();

  const latestSubscription = getLatestSubscription(user);

  const handleConfirm = async () => {
    if (latestSubscription?.status === 'past_due') {
      history.push('/update-payment?showSetupForm=true');
    } else if (latestSubscription?.status === 'lapsed') {
      await addSubscriptionCartItem({
        amountInUserCurrency: latestSubscription.amount,
        portfolioId: latestSubscription.portfolioId,
        billingCycle: latestSubscription.billingCycle,
      });

      history.push('/checkout/payment');
    }
  };

  useEffect(() => {
    window.analytics.track('Client: User Saw Cancelled Subscription Modal');
    posthog.capture('Client: User Saw Cancelled Subscription Modal');
  }, []);

  if (!latestSubscription || !user.currency) {
    return null;
  }

  return (
    <>
      <div css={topAnnouncement('var(--error-red)')}>
        <div
          css={announcementContent}
          role="button"
          onClick={() => setShow(true)}
        >
          <p>
            Your subscription is not current.{' '}
            <Link
              css={whiteLink}
              to="/settings/subscription"
              onClick={(event) => event.stopPropagation()}
            >
              Update
            </Link>
          </p>
        </div>
      </div>
      <Dialog
        withCloseButton
        id="resubscribe"
        showDialog={show}
        setShowDialog={(value: boolean) => setShow(value)}
      >
        <Content>
          <Image src={staticImageUrl('expired-credit-card.png')} />
          <Title>Please check your payment method</Title>
          {latestSubscription?.status === 'past_due' ? (
            <>
              <Description>
                Your subscription is past due. Your payment method may be
                expired or incorrect. If you have not done so already, please
                update your payment method as necessary to continue your
                subscription.
              </Description>
              <Button
                size="medium"
                type="green"
                onClick={() => handleConfirm()}
              >
                Update payment
              </Button>
            </>
          ) : (
            <>
              <Description>
                Your subscription has lapsed. Your payment method may be expired
                or incorrect. Please check out with a current payment method to
                restart your subscription for{' '}
                {formatCurrency(latestSubscription.amount, {
                  displayCurrency: latestSubscription.currency ?? 'USD',
                  amountCurrency: latestSubscription.currency ?? 'USD',
                  fromLowestDenominator: true,
                })}{' '}
                per {latestSubscription.billingCycle}
                {latestSubscription.feeAmount !== null &&
                  ', plus processing fee'}
                .
              </Description>
              <Button
                size="medium"
                type="green"
                onClick={() => handleConfirm()}
              >
                Check out
              </Button>
            </>
          )}
        </Content>
      </Dialog>
    </>
  );
}
