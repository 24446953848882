/** @jsxImportSource @emotion/react */
import { useState } from 'react';

import { Link } from '../../components/UI/Link';
import { scale } from '../../util/scale';
import { staticImageUrl } from '../../util/images';

import { fonts, spacing } from '../../style/theme';
import { Headings, Paragraph } from '../../components/UI/TextStyles';
import { hideOnMobile, showOnMobile } from '../../style/misc';
import { NEWEST_FINANCIAL_REPORT_URL } from '../../config/constants';
import useIsMobile from '../../hooks/useIsMobile';

const testimonials = [
  {
    author: 'Armin Samii',
    handle: '@ArminSamii',
    image: staticImageUrl('armin-samii.jpg', { w: 100 }),
    quote:
      'New company but seems incredibly transparent and realistic. Looked into other carbon offsets and felt they were just doing lip service [...] Wren feels real, with obvious, researchable, meaningful offsets.',
  },
  {
    author: 'Korey Krauskopf',
    image: staticImageUrl('wren_profile.svg'),
    quote:
      'Wren is very open with both their intention, their processes and their execution. Very impressed with this service over the past year, will not only continue to be part of it but will increase my contribution.',
  },
  {
    author: 'Paul Yacoubian',
    image: staticImageUrl('investors/wren-paul-yacoubian.jpg', { w: 100 }),
    quote:
      'I reviewed about a dozen other carbon offset platforms out there, but none of the others gave me the level of transparency into the underlying projects as Wren. I’m a happy subscriber!',
  },
  {
    author: 'Sally Rogers',
    image: staticImageUrl('sally-profile.jpeg', { w: 100 }),
    quote:
      'Wren has figured out the balance of offsetting your carbon emissions in both a simple and educational way. [...] Their project updates are awesome, so you feel really connected with where your dollar goes.',
  },
  {
    author: 'Celo',
    image: staticImageUrl('testimonials/celo.jpg', { w: 100 }),
    handle: '@CeloOrg',
    quote:
      'We began in 2020 with the intention of becoming the first carbon neutral platform by contributing daily offsets through the network protocol. 🌿To date, Celo has offset 2285.2 tons of CO2 through its work with Wren!',
  },
  {
    author: 'Dyani',
    image: staticImageUrl('testimonials/dyani.png', { w: 100 }),
    handle: '@dyani',
    quote:
      'Uhh wow. This carbon footprint tool is incredible. Def gonna make some lifestyle changes now, even if only little ones. 🙌 Thank you <span style="color: var(--link-blue)">@project_wren</span>',
  },
  {
    author: 'Sarah Eutsler',
    handle: '@seutsler',
    image: staticImageUrl('testimonials/sarah.png', { w: 100 }),
    quote:
      'I continue to be so impressed <span style="color: var(--link-blue)">@project_wren</span> and their donor engagement, including these new individual pages to show off your impact: wren.co/profile/saraheutsler <br /><br />So cool--and a big source of inspiration for my non-profit client!',
  },
  {
    author: 'Abigail Africa',
    handle: '@abigailafrica',
    image: staticImageUrl('uploads/716d3206-45e7-4588-986c-ba423b2eeb92.png', {
      w: 100,
    }),
    quote:
      'Wren gave me perspective on the stories of people who are fighting climate change up close. Looking forward to the next update!',
  },
  {
    author: 'Gustaf Alstromer',
    handle: '@gustaf',
    image: staticImageUrl('gustaf-profile.png', { w: 100 }),
    quote:
      'I do a lot to reduce my carbon footprint but some things can’t be avoided. I use Wren to offset the rest every month.',
  },
  {
    author: 'Adrian O Flynn',
    handle: '@AdrianOFlynn',
    image: staticImageUrl('testimonials/adrian.png', { w: 100 }),
    quote:
      'This is fundraising and world-changing genius. <br /><br />I just learned that carbon footprint is 15.7 tonnes per year and I can offset that by donating €19 per month to community tree-planting in East Africa. In about 60 seconds... wren.co',
  },
  {
    author: 'Geoffrey Litt',
    handle: '@geoffreylitt',
    image: staticImageUrl('testimonials/geoffrey.png', { w: 100 }),
    quote:
      'Just signed up for <span style="color: var(--link-blue)">@project_wren</span>. Nice simple workflow: 1. calculate your carbon footprint 2. donate to specific, detailed, high-quality projects to offset your emissions (~$20/month)',
  },
  {
    author: 'Phil',
    handle: 'Trustpilot Review',
    quote:
      "Wren is the first tangible means I have found to move the climate emergency needle in the right direction. I offset 200% of my monthly carbon footprint. To say I'm a huge fan is a vast understatement.",
    image: staticImageUrl('testimonials/phil.png', { w: 100 }),
  },
  {
    author: 'Silvia Martín',
    handle: 'Trustpilot Review',
    quote:
      'I have recently joined, but all I have to say is that Wren made me believe that it is not all lost, that there are many people who are willing to fight and not surrender: it returned my lost faith on humanity. I am proud of this community and I want to do everything I can to help them.',
    image: staticImageUrl('testimonials/silvia.png', { w: 100 }),
  },
  {
    author: 'Victor Van Sande',
    quote:
      "If that isn't enough; talking to their founders is a pleasure as well. They are quick to respond, professional and very friendly. I discovered this project via a sponsored YouTube video. Normally I skip past these, but Wren instantly piqued my interest. I went to their website - which is beautifully designed - and could easily calculate my carbon footprint. Afterwards, I could choose how much of my CO² emissions I would like to combat. For the price of a coffee per month, I can reinvest 50% of my carbon emissions back into nature, all via Wren!",
    handle: 'Trustpilot Review',
    image: staticImageUrl('testimonials/victor.png', { w: 100 }),
  },
  {
    author: 'Ryan Delk',
    handle: '@delk',
    quote:
      'If you’re looking for a simple + high leverage (rare combo) way to fight climate change, YC company <span style="color: var(--link-blue)">@project_wren</span> is super cool. <br /><br />For $20/mo, we’ve offset ~30 tons of CO2 in a year.',
    image: staticImageUrl('testimonials/ryan.jpg', { w: 100 }),
  },
  {
    author: 'Delphine Ruaro',
    handle: '@delphineruaro',
    quote:
      'Gotta cheer <span style="color: var(--link-blue)">@project_wren</span> for your product execution! everything is so easy to do, used your flight offset for the first time today after a year of being a member, it was so easy to use + love the "duolingo" golden cards/badges! 👏👏',
    image: staticImageUrl('testimonials/delphine.jpg', { w: 100 }),
  },
  {
    author: 'Lolitta Tracy',
    handle: '@lolaonearth',
    quote:
      'Offset my very first flight this morning, equivalent to 7.80 tons of CO2 (or how much 358 pine trees absorb in a year)! Thanks for making it so easy, <span style="color: var(--link-blue)">@project_wren</span> 🌲',
    image: staticImageUrl('testimonials/lolitta.jpg', { w: 100 }),
  },
  {
    author: 'Amelia Wattenberger',
    handle: '@Wattenberger',
    quote:
      "I spend a lot of time thinking about climate change. I'm definitely not holier-than-thou about it (I personally want to do more) but I do like to offset my carbon footprint with Wren",
    image: staticImageUrl('testimonials/amelia.jpg', { w: 100 }),
  },
  {
    author: 'cche_n',
    handle: '@cche_n',
    quote:
      '<span style="color: var(--link-blue)">@project_wren</span> is just surprisingly great in so many ways. I wrote in a couple months back asking for this as a #babyshower gift for the new parents in my life with eco-anxiety. #climatechange',
    image: staticImageUrl('testimonials/cche.jpg', { w: 100 }),
  },
  {
    author: 'Ryan Allard',
    handle: '@RyanFAllard',
    quote:
      'If Public Benefit Corps is the future of capitalism, then the future is bright. <span style="color: var(--link-blue)">@project_wren</span> you guys are inspiring.',
    image: staticImageUrl('testimonials/ryan_a.jpg', { w: 100 }),
  },
];

const bubblePositions = [
  { left: '5%', top: '20%' },
  { left: '15%', top: '30%' },
  { left: '25%', top: '39%' },
  { left: '35%', top: '34%' },
  { left: '45%', top: '20%' },
  { left: '55%', top: '36%' },
  { left: '65%', top: '23%' },
  { left: '75%', top: '30%' },
  { left: '85%', top: '20%' },
  { left: '95%', top: '30%' },

  { left: '10%', top: '50%' },
  { left: '28%', top: '65%' },
  { left: '38%', top: '53%' },
  { left: '48%', top: '64%' },
  { left: '58%', top: '56%' },
  { left: '68%', top: '60%' },
  { left: '78%', top: '53%' },
  { left: '88%', top: '66%' },

  { left: '5%', top: '20%' },
  { left: '20%', top: '70%' },
  { left: '30%', top: '20%' },
  { left: '40%', top: '70%' },
  { left: '50%', top: '20%' },
  { left: '60%', top: '70%' },
  { left: '70%', top: '20%' },
  { left: '80%', top: '70%' },

  { left: '15%', top: '80%' },
  { left: '25%', top: '40%' },
  { left: '35%', top: '80%' },
  { left: '45%', top: '40%' },
  { left: '55%', top: '80%' },
  { left: '65%', top: '40%' },
  { left: '75%', top: '80%' },
  { left: '90%', top: '40%' },
];

const testimonialSizes = [
  scale({
    borderRadius: [30, 66],
    width: [30, 66],
    height: [30, 66],
  }),
  scale({
    borderRadius: [40, 50],
    width: [40, 50],
    height: [40, 50],
  }),
  scale({
    borderRadius: [45, 64],
    width: [45, 64],
    height: [45, 64],
  }),
];

const header = scale({
  fontSize: ['30px', '30px', '30px', '35px'],
  lineHeight: ['35px', '35px', '35px', '40px'],
});

const methodHeader = scale({
  marginTop: ['0px !important', '0px !important', 32, 32],
});

const methodSection = scale({
  position: 'relative',
  zIndex: 4,
  backgroundColor: ['black', 'black', 'inherit', 'inherit'],
  color: ['white', 'white', 'inherit', 'inherit'],
  padding: ['24px 24px', '0 24px', 0, 0],
  gridRow: [2, 2, 'auto', 'auto'],
});

const projectSection = scale({
  margin: ['0 auto', '0 auto', '50px auto 120px', '50px auto 120px'],
  display: 'grid',
  gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
  gridGap: [0, 0, 50, 100],
});

const projectImageGradientDesktop = (url) => `
    linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.73)
    ), url('${url}')
`;

const projectImageGradientMobile = (url) => `
    linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 1)
    ), url('${url}')
`;

const projectImageSlate = (bgImageUrl) =>
  scale({
    backgroundImage: [
      projectImageGradientMobile(bgImageUrl),
      projectImageGradientMobile(bgImageUrl),
      projectImageGradientDesktop(bgImageUrl),
      projectImageGradientDesktop(bgImageUrl),
    ],
    backgroundSize: 'cover',
    height: [400, 400, 650, 650],
    padding: '0 110px 46px 46px',
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    borderRadius: [0, 0, 4, 4],
    position: 'relative',
    zIndex: 3,
    hr: {
      display: ['none', 'none', 'block', 'block'],
      border: 0,
      height: 5,
      borderRadius: 20,
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
    },
  });

const projectImageSlateHeader = scale({
  display: ['none', 'none', 'block', 'block'],
  fontSize: 37,
  fontWeight: 600,
  fontFamily: fonts.Sans,
  lineHeight: 1,
  color: 'var(--text-light)',
});

const projectImageSlateDescription = scale({
  display: ['none', 'none', 'block', 'block'],
  fontSize: 22,
  fontWeight: 400,
  fontFamily: fonts.Serif,
  fontStyle: 'italic',
  lineHeight: 1.3,
  color: 'var(--text-light)',
  opacity: 0.8,
  marginTop: 12,
});

const benefitListItem = {
  display: 'flex',
  alignItems: 'flex-start',
  textAlign: 'left',
  svg: {
    flexShrink: 0,
  },
};

const smallHeader = scale({
  fontSize: 18,
  fontWeight: 400,
  fontFamily: fonts.Sans,
  lineHeight: 1.4,
  textAlign: 'center',
});

const benefitTextContent = scale({
  marginTop: 0,
  marginBottom: 42,
  marginLeft: 16,
  h4: {
    marginBottom: 6,
    fontSize: 18,
    fontWeight: 600,
  },
  p: {
    textAlign: 'left !important',
    lineHeight: 1.4,
    fontSize: '18px !important',
    fontFamily: `${fonts.Sans} !important`,
    color: ['white', 'var(--gray-6)'],
    opacity: [0.8, 1],
  },
});

const partnerLogosImage = scale({
  width: [198, '90%', '80%'],
  margin: ['24px auto 50px', '16px auto 72px', '24px auto 50px'],
  display: 'block',
  maxWidth: 1000,
});

const partnerLogosSection = scale({
  borderTop: '6px solid var(--gray-9)',
  borderBottom: '6px solid var(--gray-9)',
  paddingTop: [42, spacing.large, 24],
  paddingBottom: [42, spacing.default, 0],
  margin: [0, 0, '32px 0', '32px 0'],
});

const partnersHeader = [
  scale({
    fontWeight: 400,
    maxWidth: [260, 'none'],
    textAlign: 'center',
  }),
];

const testimonialsContainer = scale({
  display: 'grid',
  gridTemplateColumns: ['1fr', '1fr', '1fr 1fr 1fr', '1fr 1fr 1fr'],
  gridGap: 40,
  margin: '32px auto',
  width: '100%',
  maxWidth: 1000,
});

const testimonial = scale({
  textAlign: 'center',
  a: {
    color: 'var(--link-blue)',
    textDecoration: 'none',
  },
  p: {
    fontSize: 18,
    lineHeight: 1.4,
  },
});

const transparencyContainer = scale({
  backgroundColor: 'var(--background-gray)',
  paddingTop: [45, 45, 100, 100],
  paddingBottom: [45, 45, 100, 100],
  paddingLeft: [24, 24, spacing.medium, 100],
  paddingRight: [24, 24, spacing.medium, 100],
  boxSizing: 'border-box',
  textAlign: 'center',
});

const Icons = {
  Ruler: ({ className }) => {
    return (
      <svg
        className={className}
        width="19"
        height="20"
        viewBox="0 0 19 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M5.03531 19.0064C5.12303 19.0051 5.2067 18.9693 5.2681 18.9066L18.9033 5.27143C18.9652 5.20912 19 5.12483 19 5.03697C19 4.94911 18.9652 4.86482 18.9033 4.80251L14.2041 0.0967077C14.1418 0.034767 14.0575 0 13.9697 0C13.8818 0 13.7975 0.034767 13.7352 0.0967077L12.7941 1.03454L14.4569 2.69737L13.988 3.16961L12.3251 1.52009L11.384 2.46125L13.968 5.04528L13.4925 5.51088L10.9117 2.91686L9.97058 3.84805L11.6334 5.51088L11.1645 5.97314L9.50166 4.32694L8.5605 5.2681L10.2067 6.9143L9.73778 7.38655L8.09159 5.74035L7.15042 6.67818L9.73778 9.26554L9.26554 9.73779L6.67818 7.15042L5.74035 8.09159L7.38655 9.73779L6.9143 10.2067L5.2681 8.5605L4.32694 9.50166L5.97314 11.1645L5.51088 11.6168L3.84805 9.95395L2.91686 10.9117L5.51088 13.4925L5.04196 13.9647L2.4546 11.3773L1.52009 12.3251L3.18292 13.9713L2.71067 14.4403L1.06447 12.7774L0.0967076 13.7352C0.034767 13.7975 0 13.8818 0 13.9697C0 14.0575 0.034767 14.1418 0.0967076 14.2041L4.79919 18.9066C4.82999 18.938 4.86672 18.963 4.90725 18.9802C4.94778 18.9973 4.99131 19.0062 5.03531 19.0064Z" />
      </svg>
    );
  },
  Recycle: ({ className }) => {
    return (
      <svg
        className={className}
        width="19"
        height="19"
        viewBox="0 0 19 19"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M17.3402 9.88403C17.3498 9.71805 17.3562 9.55087 17.3562 9.38489C17.3562 4.99119 14.0691 1.28775 9.71047 0.770054L9.45172 2.94935C12.7069 3.33597 15.1617 6.10258 15.1617 9.38489V9.38728L13.5418 9.01762L15.6485 12.3684L19 10.2627L17.3402 9.88403ZM8.67808 15.8685C7.52918 15.8685 6.42038 15.5689 5.43826 15.0003L6.56601 13.7916L2.61041 13.9294L2.74706 17.885L3.91232 16.6358C5.32914 17.5694 6.97159 18.0626 8.67828 18.0626C11.4353 18.0626 13.9669 16.7962 15.6237 14.5877L13.8684 13.2707C12.6297 14.9217 10.7381 15.8685 8.67808 15.8685ZM2.19446 9.38489C2.19446 7.07094 3.45068 4.92516 5.43407 3.77446L5.91645 5.35387L7.77496 1.8595L4.28118 0L4.77992 1.63327C1.86948 3.09937 0 6.11734 0 9.38489C0 10.5691 0.235405 11.7184 0.699433 12.8009L2.71614 11.9362C2.37001 11.1287 2.19446 10.2703 2.19446 9.38489Z" />
      </svg>
    );
  },
  Money: ({ className }) => {
    return (
      <svg
        className={className}
        height="19"
        viewBox="0 0 19 19"
        width="19"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M9.5 0C4.26 0 0 4.24873 0 9.47488C0 14.701 4.26 18.9498 9.5 18.9498C14.74 18.9498 19 14.701 19 9.47488C19 4.24873 14.74 0 9.5 0ZM11.34 13.1252L11.28 13.185C10.94 13.4842 10.52 13.6837 10.06 13.7834V15.08H8.7V13.7436C8.32 13.6438 7.98 13.4643 7.68001 13.2249C7.22001 12.8459 6.94 12.3273 6.86 11.7688L8.26 11.5693C8.28 11.7887 8.4 11.9882 8.6 12.1478C9.1 12.5467 9.9 12.5467 10.36 12.1278C10.64 11.8685 10.78 11.5095 10.76 11.1504C10.74 10.8113 10.58 10.5121 10.3 10.2927C9.96 10.0533 9.64 9.97355 9.26 9.89376C8.76 9.77408 8.18 9.63446 7.66 9.11583C7.16 8.59721 6.9 7.9589 6.92 7.34053C6.94 6.76207 7.2 6.22349 7.66 5.8046L7.68001 5.78466C7.70001 5.76472 7.7 5.76472 7.7 5.76472C7.98 5.52535 8.34 5.36577 8.7 5.24609V3.88969H10.06V5.22615C10.5 5.32588 10.88 5.5453 11.22 5.82456C11.56 6.12377 11.8 6.50276 11.92 6.92165L10.5 7.14106C10.46 7.04133 10.38 6.96154 10.3 6.88175C9.84 6.48281 9.06 6.48282 8.6 6.86181C8.46 7.00144 8.31999 7.18096 8.31999 7.42033C8.3 7.65969 8.44 7.91901 8.66 8.15837C8.88 8.39774 9.12 8.45758 9.58 8.55732C10.02 8.65705 10.58 8.77672 11.14 9.19561C11.74 9.63445 12.12 10.3326 12.16 11.0906C12.18 11.8286 11.9 12.5667 11.34 13.1252Z" />
      </svg>
    );
  },
  Photo: ({ className }) => {
    return (
      <svg
        className={className}
        height="17"
        viewBox="0 0 19 17"
        width="19"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.333374 2.20005V14.8C0.333374 15.9758 0.571374 16.2001 1.73337 16.2001H17.6C18.7525 16.2001 19 15.9854 19 14.8V2.20005C19 1.05205 18.7805 0.800049 17.6 0.800049H1.73337C0.575807 0.800049 0.333374 1.00048 0.333374 2.20005ZM16.802 12.9334H2.54094C4.09961 10.6607 6.87137 6.87138 7.57581 6.87138C8.25761 6.87138 10.5718 9.93295 11.6171 11.0107C11.6171 11.0107 12.9616 9.21428 13.666 9.21428C14.3845 9.21428 16.7787 12.9147 16.802 12.9334ZM12.6116 5.00005C12.6116 4.04805 13.3814 3.27828 14.3334 3.27828C15.2854 3.27828 16.0551 4.04805 16.0551 5.00005C16.0551 5.95205 15.2854 6.72182 14.3334 6.72182C13.3814 6.72182 12.6116 5.95205 12.6116 5.00005Z"
        />
      </svg>
    );
  },
  Chart: ({ className }) => {
    return (
      <svg
        className={className}
        height="19"
        viewBox="0 0 20 19"
        width="20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M9.0526 10.1109V1.27159C9.0526 1.27159 9.03386 1.26904 9.01642 1.26904C4.03242 1.26904 0 5.24497 0 10.1497C0 15.0557 4.04469 18.9999 9.02869 18.9999C14.0049 18.9999 18.0438 15.1969 18.0567 10.1109H9.0526Z" />
        <path d="M19.3804 8.25551C19.3804 8.25551 19.3659 8.27036 19.3659 8.26264C19.3659 3.69788 15.8025 0.026125 10.9822 0V8.25492H19.3804V8.25551Z" />
      </svg>
    );
  },
  Quotation: ({ className }) => {
    return (
      <svg
        className={className}
        height="16"
        viewBox="0 0 18 16"
        width="18"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M2.14831 3.0311C0.906318 4.54693 0 6.69731 0 9.41172V15.1226H5.94142C6.91487 15.1226 7.68692 14.3118 7.68692 13.2895V6.97933H3.32317C3.55814 6.02752 3.96095 5.25198 4.46446 4.61744C5.00153 3.94765 5.63931 3.41887 6.54563 3.06635C7.21698 2.81958 7.65335 2.14979 7.65335 1.4095V0.034668C4.90083 0.457693 3.3903 1.48 2.14831 3.0311Z" />
        <path d="M16.3472 3.03156C17.0185 2.78481 17.4549 2.11504 17.4549 1.37478V0C14.6688 0.49351 13.1583 1.48053 11.9163 3.03156C10.6743 4.58259 9.76794 6.69764 9.76794 9.41194V15.1226H15.7094C16.6828 15.1226 17.4549 14.3118 17.4549 13.2895V6.97964H13.1247C13.3597 6.02787 13.7625 5.25236 14.266 4.61784C14.8031 3.91283 15.4408 3.38407 16.3472 3.03156Z" />
      </svg>
    );
  },
};

export function MidPageCTA({ cta }) {
  return (
    <div
      css={scale({
        margin: ['0px 0px 80px 0px', '100px auto'],
        textAlign: 'center',
        position: 'relative',
      })}
    >
      <Headings.Large
        font="serif"
        css={[
          hideOnMobile,
          scale({
            maxWidth: ['100%', '100%', 680, 680],
            marginBottom: 24,
            fontSize: [28, 28, '40px !important', '40px !important'],
          }),
        ]}
      >
        The climate crisis is a global problem. Be part of the solution.
      </Headings.Large>
      {cta}
    </div>
  );
}

const calloutContainer = (width, height) =>
  scale({
    backgroundColor: 'white',
    border: '2px solid var(--gray-1)',
    borderRadius: 4,
    boxSizing: 'border-box',
    height,
    margin: '20px auto 0',
    maxWidth: '100%',
    overflow: 'hidden',
    overflowY: 'scroll',
    padding: '25px',
    textAlign: 'center',
    width,
  });

const floatingCalloutContainer = (width, height) => [
  calloutContainer(width, height),
  scale({
    position: ['relative', 'absolute'],
    left: [0, '50%'],
    top: 0,
    transform: ['', 'translateX(-50%)'],
    zIndex: 100,
  }),
];

// TODO: merge UserTestimonials and MobileUserTestimonials into a single component
export function UserTestimonials({ className }) {
  const isMobile = useIsMobile(false);
  const testimonialsToDisplay = isMobile
    ? testimonials.slice(0, 10)
    : testimonials;

  const [selectedTestimonial, setSelectedTestimonial] = useState(
    testimonials[0]
  );

  return (
    <div
      css={scale({
        background: 'var(--background-gray)',
        padding: ['50px 0', 60],
      })}
      className={className}
    >
      <Headings.Large
        font="serif"
        css={scale({
          textAlign: 'center',
          maxWidth: [300, 500],
          fontSize: [undefined, '40px !important'],
          textAlign: 'center',
        })}
      >
        Members from{' '}
        <span css={scale({ fontSize: [undefined, 48] })}>100+</span> countries
        love using Wren!
      </Headings.Large>
      <div css={{ position: 'relative' }}>
        <div
          css={scale({
            position: 'relative',
            height: 400,
            marginTop: [-50, 30],
            marginBottom: [-175, 0],
            marginRight: 30,
          })}
          onClick={(event) => {
            if (!event.target.classList.contains('faceCircle')) {
              setSelectedTestimonial(null);
            }
          }}
        >
          {testimonialsToDisplay.map((testimonial, i) => {
            const size = testimonialSizes[i % 3];
            const position = bubblePositions[i];

            return (
              <div
                className="faceCircle"
                onClick={() => setSelectedTestimonial(testimonial)}
                css={[
                  {
                    ...position,
                    position: 'absolute',
                    cursor: 'pointer',
                    backgroundImage: `url(${testimonial.image})`,
                    backgroundSize: 'cover',
                    transition: 'all .2s ease-in-out',
                    boxShadow:
                      testimonial.author === selectedTestimonial?.author &&
                      'var(--success-green) 0px 0px 15px',
                    '&:hover': {
                      transform: 'scale(1.15)',
                      boxShadow:
                        testimonial.author === selectedTestimonial?.author
                          ? 'var(--success-green) 0px 0px 15px'
                          : '0 2px 5px 0 var(--box-shadow-color)',
                    },
                  },
                  size,
                ]}
                key={i}
              />
            );
          })}
        </div>
        <Paragraph
          css={{
            textAlign: 'center',
            marginTop: 8,
            color: 'var(--gray-6)',
            fontStyle: 'italic !important',
          }}
        >
          Click on a face to read a review.
        </Paragraph>
        {selectedTestimonial && (
          <div css={floatingCalloutContainer(350, 350)}>
            <div
              css={{
                margin: '0 auto 12px',
                width: 60,
                height: 60,
                borderRadius: 60,
                backgroundImage: `url(${selectedTestimonial.image})`,
                backgroundSize: 'cover',
              }}
            />
            <Paragraph css={{ fontWeight: '600 !important' }}>
              {selectedTestimonial.author}
            </Paragraph>
            <Paragraph
              css={{
                fontWeight: '400 !important',
                marginBottom: 18,
                opacity: 0.3,
              }}
            >
              {selectedTestimonial.handle}
            </Paragraph>
            <Paragraph>
              “
              <span
                dangerouslySetInnerHTML={{
                  __html: selectedTestimonial.quote,
                }}
              />
              ”
            </Paragraph>
          </div>
        )}
      </div>
    </div>
  );
}

// TODO: merge UserTestimonials and MobileUserTestimonials into a single component
export function MobileUserTestimonials({ className }) {
  const isMobile = useIsMobile();
  const [selectedTestimonial, setSelectedTestimonial] = useState(
    testimonials[1]
  );

  return (
    <div
      css={{ background: 'var(--background-gray)', padding: '50px 0' }}
      className={className}
    >
      <Headings.Large
        font="serif"
        css={{
          textAlign: 'center',
          maxWidth: 300,
        }}
      >
        Members from 100+ countries love using Wren!
      </Headings.Large>
      <div css={{ position: 'relative', height: 200 }}>
        {testimonials.slice(0, 10).map((testimonial, i) => {
          const size = (isMobile ? [30, 40, 45] : [66, 50, 64])[i % 3];
          const position = bubblePositions[i];

          return (
            <div
              className="faceCircle"
              onClick={() => setSelectedTestimonial(testimonial)}
              css={{
                ...position,
                top:
                  i % 2 === 0
                    ? `calc(${position.top} + 5%)`
                    : `calc(${position.top} + 30%)`,
                position: 'absolute',
                cursor: 'pointer',
                width: size,
                height: size,
                borderRadius: size,
                backgroundImage: `url(${testimonial.image})`,
                backgroundSize: 'cover',
                boxShadow:
                  testimonial.author === selectedTestimonial.author &&
                  'var(--success-green) 0px 0px 15px',
              }}
            />
          );
        })}
      </div>
      <Paragraph
        css={{
          textAlign: 'center',
          marginTop: 8,
          color: 'var(--gray-6)',
          fontStyle: 'italic !important',
        }}
      >
        Click on a face to read a review.
      </Paragraph>

      <div css={calloutContainer(300, 300)}>
        <div
          css={{
            margin: '0 auto 12px',
            width: 66,
            height: 66,
            borderRadius: 66,
            backgroundImage: `url(${selectedTestimonial.image})`,
            backgroundSize: 'cover',
          }}
        />
        <Paragraph css={{ fontWeight: '600 !important' }}>
          {selectedTestimonial.author}
        </Paragraph>
        <Paragraph
          css={{
            fontWeight: '400 !important',
            marginBottom: 18,
            opacity: 0.3,
          }}
        >
          {selectedTestimonial.handle}
        </Paragraph>
        <Paragraph>
          “
          <span
            dangerouslySetInnerHTML={{
              __html: selectedTestimonial.quote,
            }}
          />
          ”
        </Paragraph>
      </div>
    </div>
  );
}

export function Method({ className }) {
  const iconFillStyle = ['white', 'white', 'var(--gray-2)'];

  return (
    <div className={className}>
      <div
        css={[
          scale({
            textAlign: 'center',
            padding: ['50px 0 24px', '50px 0 24px', '50px 0 0', '50px 0 0'],
            backgroundColor: 'white',
            color: 'var(--text-primary)',
            margin: '0px !important',
          }),
        ]}
      >
        <Headings.Large
          font="serif"
          css={scale({
            maxWidth: [350, 350, 600, 600],
            position: 'relative',
            marginTop: 40,
            marginBottom: 0,
            fontSize: [28, 28, '40px !important', '40px !important'],
          })}
        >
          Our goal is to make climate action <em>simple & effective</em>.
        </Headings.Large>
        <span css={scale({ display: ['block', 'block', 'none'] })}>
          <img
            src={staticImageUrl('down-chevron-gradient.png')}
            css={{
              display: 'block',
              margin: '0 auto',
              width: 13,
              paddingTop: 16,
            }}
          />
        </span>
      </div>
      <div css={projectSection}>
        <div>
          <div
            css={projectImageSlate(
              staticImageUrl('protect-rainforest.jpg', { h: 800 })
            )}
          >
            <div>
              <h2 css={projectImageSlateHeader}>
                Protect rainforest in the Amazon
              </h2>
              <hr />
              <p css={projectImageSlateDescription}>
                Wren members fund a project that works with indigenous peoples
                to protect their land, a natural carbon sink.
              </p>
            </div>
          </div>
        </div>
        <div css={methodSection}>
          <h2
            css={[
              methodHeader,
              header,
              scale({
                fontFamily: fonts.Serif,
                fontWeight: '600',
                fontSize: [28, 28, '40px !important', '40px !important'],
                lineHeight: '115% !important',
              }),
            ]}
          >
            We search the globe for climate projects with the biggest potential.
          </h2>
          <p
            css={scale({
              fontSize: 18,
              lineHeight: 1.4,
              marginBottom: 32,
              marginTop: 16,
              color: ['white', 'white', 'var(--gray-6)'],
            })}
          >
            All Wren projects meet three rigorous criteria:
          </p>
          <div css={benefitListItem}>
            <Icons.Ruler css={scale({ fill: iconFillStyle })} />
            <div css={benefitTextContent}>
              <h4>Precise tracking</h4>
              <p>
                We carefully check how much CO₂ is offset by each project, so we
                can promise you lasting impact.
              </p>
            </div>
          </div>
          <div css={benefitListItem}>
            <Icons.Recycle css={scale({ fill: iconFillStyle })} />
            <div css={benefitTextContent}>
              <h4>Regular updates</h4>
              <p>
                We get data straight from projects on the ground, so you can
                track your impact, month by month.
              </p>
            </div>
          </div>
          <div css={benefitListItem}>
            <Icons.Money css={scale({ fill: iconFillStyle })} />
            <div css={benefitTextContent}>
              <h4>Every dollar makes a difference</h4>
              <p>
                Our projects wouldn't happen without your support. With Wren,
                your money makes a real impact.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div css={projectSection}>
        <div css={methodSection}>
          <h2
            css={[
              header,
              methodHeader,
              scale({
                fontFamily: fonts.Serif,
                fontWeight: '600',
                fontSize: [28, 28, '40px !important', '40px !important'],
                lineHeight: '115% !important',
              }),
            ]}
          >
            Then, we send you personalized updates every month.
          </h2>
          <p
            css={scale({
              fontSize: 18,
              lineHeight: 1.4,
              marginBottom: 32,
              marginTop: 16,
              color: ['white', 'white', 'var(--gray-6)'],
            })}
          >
            Each update is filled with:
          </p>
          <div css={benefitListItem}>
            <Icons.Photo css={scale({ fill: iconFillStyle })} />
            <div css={benefitTextContent}>
              <h4>Pictures</h4>
              <p>
                You don't have to imagine your impact. You can see it each
                month.{' '}
              </p>
            </div>
          </div>
          <div css={benefitListItem}>
            <Icons.Chart css={scale({ fill: iconFillStyle })} />
            <div css={benefitTextContent}>
              <h4>Data</h4>
              <p>
                We share the details with you. Count every tree planted, every
                acre reforested, every ton of carbon offset.
              </p>
            </div>
          </div>
          <div css={benefitListItem}>
            <Icons.Quotation css={scale({ fill: iconFillStyle })} />
            <div css={benefitTextContent}>
              <h4>Stories</h4>
              <p>
                Read stories fresh from farm or forest. See how your choices
                don't just help the earth. They change lives too.
              </p>
            </div>
          </div>
        </div>
        <div>
          <div
            css={projectImageSlate(
              staticImageUrl('scotland-valley-bright.jpg', { h: 800 })
            )}
          >
            <div>
              <h2 css={projectImageSlateHeader}>Store carbon in basalt rock</h2>
              <hr />
              <p css={projectImageSlateDescription}>
                <i>
                  Wren members are funding innovative new ways to capture and
                  store carbon.
                </i>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function Partners({ className }) {
  const desktopImageUrl = staticImageUrl('projects/partners-slate.png', {
    w: 1200,
  });
  const mobileImageUrl = staticImageUrl('projects/partners-mobile-dark.png', {
    w: 500,
  });

  return (
    <div css={partnerLogosSection} className={className}>
      <Headings.Medium css={partnersHeader}>
        Our projects partner with the best in the business:
      </Headings.Medium>
      <picture>
        <source media="(max-width: 440px)" srcSet={mobileImageUrl} />
        <img src={desktopImageUrl} css={partnerLogosImage} />
      </picture>
    </div>
  );
}

export function Transparency({ className }) {
  return (
    <div
      className={className}
      css={[transparencyContainer, { marginTop: -32, marginBottom: 64 }]}
    >
      <h1
        css={[
          header,
          scale({
            fontFamily: fonts.Serif,
            fontWeight: '600',
            fontSize: [28, 28, '40px !important', '40px !important'],
            lineHeight: '115% !important',
            marginBottom: 8,
          }),
        ]}
      >
        All our work is public.
      </h1>
      <Headings.Medium css={{ maxWidth: 600, marginBottom: 64 }}>
        We’re a Benefit Corporation with a{' '}
        <a
          css={{ color: 'var(--link-blue)', textDecoration: 'none' }}
          target="_blank"
          href="/charter"
        >
          legally-binding charter
        </a>
        . It doesn’t get more transparent than that.
      </Headings.Medium>
      <div css={testimonialsContainer}>
        <div css={testimonial}>
          <img
            css={{ height: 63, marginBottom: 12 }}
            src={staticImageUrl('transparency-1.png')}
          />
          <p css={[smallHeader, { marginBottom: 8, lineHeight: 1.4 }]}>
            <b>We share every transaction</b>
          </p>
          <Headings.Small>
            Check our impact live-feed. Every transaction has a paper trail.
          </Headings.Small>
          <br />
          <Link to="/impact">View live-feed →</Link>
        </div>
        <div css={testimonial}>
          <img
            css={{ height: 63, marginBottom: 12 }}
            src={staticImageUrl('transparency-2.png')}
          />
          <p css={[smallHeader, { marginBottom: 8, lineHeight: 1.4 }]}>
            <b>We show our work</b>
          </p>
          <Headings.Small>
            Read about our revenue and funding in our new metrics recap.
          </Headings.Small>
          <br />
          <a href={NEWEST_FINANCIAL_REPORT_URL}>View report →</a>
        </div>
        <div css={testimonial}>
          <img
            css={{ height: 63, marginBottom: 12 }}
            src={staticImageUrl('transparency-3.png')}
          />
          <p css={[smallHeader, { marginBottom: 8, lineHeight: 1.4 }]}>
            <b>We publish every receipt</b>
          </p>
          <Headings.Small>
            See every carbon credit we purchase on our receipt ledger.
          </Headings.Small>
          <br />
          <a href="https://www.notion.so/Wren-Receipt-Ledger-ce9131ad39ef42c3b52f7ea6943c8e18">
            View receipts →
          </a>
        </div>
      </div>
    </div>
  );
}

export function Tweets({ className }) {
  return (
    <>
      <div
        className={className}
        css={[
          {
            padding: '180px 0',
            backgroundColor: 'rgb(246, 247, 249)',
          },
          hideOnMobile,
        ]}
      >
        <img
          loading="lazy"
          src={staticImageUrl('wren-tweets-blue-fade.png', { w: 2400 })}
          css={scale({
            display: ['none', 'none', 'block'],
            width: '90%',
            margin: '0 auto',
            maxWidth: 1200,
          })}
        />
        <img
          loading="lazy"
          src={staticImageUrl('mobile-tweets-fade-out.png', { w: 500 })}
          css={scale({
            margin: '0 auto',
            display: ['block', 'block', 'none'],
            maxWidth: 400,
          })}
        />
      </div>
      <div
        className={className}
        css={[
          {
            padding: '80px 0',
            backgroundColor: 'var(--background-gray)',
            textAlign: 'center',
          },
          showOnMobile,
        ]}
      >
        <strong css={{ display: 'block', marginBottom: 32 }}>
          What people are tweeting:
        </strong>
        <img
          loading="lazy"
          src={staticImageUrl('mobile-tweets-fade-out.png', { w: 1200 })}
          css={{
            margin: '0 auto',
            display: 'block',
            maxWidth: 400,
            width: '100%',
          }}
        />
      </div>
    </>
  );
}
